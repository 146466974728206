import React, {useState} from 'react';
import MainLogo from '../../components/MainLogo/MainLogo';
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from '../../components/Button/Button';
import developerLogo from '../../images/motion-displays-logo.png';
import configSectionImage from './images/config.svg';
import './Config.css';

function Config({isValidating, validationError, onInputFocus, onConfigChange}) {
    const [company, setCompany] = useState(undefined);
    const [instance, setInstance] = useState(undefined);
    const [errors, setErrors] = useState([]);

    const handleCompanyChange = (value) => {
        setCompany(value);
    };

    const handleInstanceChange = (value) => {
        setInstance(value);
    };

    const handleCompanyFocus = () => {
        if (onInputFocus) onInputFocus();
        clearCompanyErrors();
    };

    const handleCompanyAnyKeyPress = () => {
        clearCompanyErrors();
    };

    const handleCompanyEnterKeyPress = () => {
        clearCompanyErrors();
        handleConfigChange();
    };

    const handleInstanceFocus = () => {
        if (onInputFocus) onInputFocus();
        clearInstanceErrors();
    };

    const handleInstanceAnyKeyPress = () => {
        clearInstanceErrors();
    };

    const handleInstanceEnterKeyPress = () => {
        clearInstanceErrors();
        handleConfigChange();
    };

    const getErrors = (type) => {
        return errors.filter(error => error.type === type);
    };

    const clearCompanyErrors = () => {
        clearErrors('company');
    };

    const clearInstanceErrors = () => {
        clearErrors('instance');
    };

    const clearErrors = (type) => {
        let newErrors = errors.filter(error => error.type !== type);
        setErrors(newErrors);
    };

    const handleConfigChange = () => {
        clearErrors();
        let newErrors = [];
        if (!company) newErrors.push({type: 'company', message: 'Debe escribir el nombre de la empresa'});
        if (!instance) newErrors.push({type: 'instance', message: 'Debe escribir el identificador del Kiosco'});
        setErrors(newErrors);
        if (newErrors.length === 0 && onConfigChange) onConfigChange(company, instance);
    };

    const companyErrors = getErrors('company');
    const instanceErrors = getErrors('instance');

    return (
        <div className='config'>
            <div className='config-wrapper'>
                <div className='config-client-logo'>
                    <MainLogo
                        backgroundColor='transparent'
                        backgroundTopPadding={20}
                        backgroundRightPadding={50}
                        backgroundBottomPadding={20}
                        backgroundLeftPadding={50}
                        backgroundWidth='100%'
                        image={`${developerLogo}`}
                        imageAlign='center'
                        imageWidth={250}
                    />
                </div>
                <div className='config-section-image-container'>
                    <img className='config-section-image' src={`${configSectionImage}`} alt='Configuración'/>
                </div>
                <div className='config-options'>
                    <div className='config-options-wrapper'>
                        <div className='config-option'>
                            <CustomInput
                                id='config-company'
                                label='Código de empresa:'
                                placeholder='Ingrese el código de la empresa'
                                initialValue={undefined}
                                onFocus={handleCompanyFocus}
                                onChange={handleCompanyChange}
                                onAnyKeyPress={handleCompanyAnyKeyPress}
                                onEnterKeyPress={handleCompanyEnterKeyPress}
                            />
                            {companyErrors && companyErrors.length > 0 && (companyErrors.map(error => <div className='config-error'>{error.message}</div>))}
                        </div>
                        <div className='config-option'>
                            <CustomInput
                                id='config-instance'
                                label='Identificador del kiosco:'
                                placeholder='Ingrese el identificador del kiosco'
                                initialValue={undefined}
                                onFocus={handleInstanceFocus}
                                onChange={handleInstanceChange}
                                onAnyKeyPress={handleInstanceAnyKeyPress}
                                onEnterKeyPress={handleInstanceEnterKeyPress}
                            />
                            {instanceErrors && instanceErrors.length > 0 && (instanceErrors.map(error => <div className='config-error'>{error.message}</div>))}
                        </div>
                    </div>
                </div>
                <div className='config-actions'>
                    <div className='config-action'>
                        <Button
                            label='Continuar'
                            labelColor='#FFFFFF'
                            backgroundColor='#0071CE'
                            borderColor='#0071CE'
                            minWidth='auto'
                            maxWidth='100%'
                            boxShadow={undefined}
                            isLoading={isValidating}
                            loadLabel='Validando...'
                            disabled={!instance}
                            onClick={handleConfigChange}
                        />
                    </div>
                </div>
                {validationError && <div className='config-error'>{validationError}</div>}
            </div>
        </div>
    );
}

export default Config;