import {addDoc, collection, Timestamp} from 'firebase/firestore';
import {firestore, getCollectionPath} from '../config/Firebase';
import Debug from './Debug';

export default class Activity {
    static log(kioskData, entityType, entityId, action, metadata) {
        const client   = kioskData && kioskData.client   ? kioskData.client      : undefined;
        const instance = kioskData && kioskData.instance ? kioskData.instance.id : undefined;
        const catalog  = kioskData && kioskData.catalog  ? kioskData.catalog.id  : undefined;
        const store    = kioskData && kioskData.store    ? kioskData.store.id    : undefined;
        const session  = kioskData && kioskData.session  ? kioskData.session.id  : undefined;
        const hasRequiredData = client && instance && catalog && store && session && entityType && entityId && action;
        if (hasRequiredData) {
            const data = {
                client: client,
                instance: instance,
                catalog: catalog,
                store: store,
                session: session,
                entity_type: entityType,
                entity_id: entityId || null,
                action: action,
                metadata: metadata || null,
                timestamp: Timestamp.now()
            };
            const _path = getCollectionPath(client, 'activity');
            const _collection = collection(firestore, _path);
            return addDoc(_collection, data)
                .catch((error) => {
                    Debug.printToLog('error', error);
                });
        } else {
            // TODO: Find a way to save log activities that does not have all the required data (for testing).
            Debug.printToLog('error', 'Activity.log() is missing required data.');
            Debug.printToLog('error', {kioskData, entityType, entityId, action, metadata});
            return Promise.resolve();
        }
    };
}