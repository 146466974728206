export default class Currency {
    static get CLP() {
        return {
            prefix: '$',
            thousandsSeparator: '.',
            decimalSeparator: ',',
            decimalScale: 0,
            suffix: '',
            symbol: 'CLP'
        };
    };
}