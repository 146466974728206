import React, {useEffect, useState, useRef} from 'react';
import CustomSelectOption from './CustomSelectOption/CustomSelectOption';
import Text from '../../helpers/Text';
import Viewport from "../../helpers/Viewport";
import selectIcon from './images/down-arrow.svg';
import './CustomSelect.css';

function CustomSelect({id, prefix, placeholder, initialValue, options, showValueInLabel, formatLabel, selectionHoverColor, selectionAccentColor, optionHoverColor, onClick, onChange}) {
    const ref = useRef(null);
    const [selectedOptionValue, setSelectedOptionValue] = useState(initialValue);
    const [showOptions, setShowOptions] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const viewport = Viewport.dimensions;

    useEffect(() => {
        setCloseListener();
        return () => {
            removeCloseListener();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCloseListener = () => {
        document.body.addEventListener('click', handleClose);
    };

    const removeCloseListener = () => {
        document.body.removeEventListener('click', handleClose);
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClose = () => {
        setShowOptions(false);
    };

    const inSmallMode = () => {
        return viewport.width < 400;
    };

    const getLabel = () => {
        const match = options.find(option => option.value === selectedOptionValue);
        if (match) {
            if (match.customRenderer) {
                return (
                    <div className='custom-select-selection-custom-container'>
                        {!inSmallMode() && <span>{prefix}&nbsp;</span>}
                        <span>{match.customRenderer()}</span>
                    </div>
                );
            } else {
                const formattedLabel = match.label ? (formatLabel ? Text.capitalizeAll(match.label) : match.label) : undefined;
                const value = showValueInLabel ? (formattedLabel ? `${formattedLabel} (${match.value})` : match.value) : (formattedLabel || match.value);
                return (prefix ? (
                    <span>
                        {!inSmallMode() && <span>{prefix}&nbsp;</span>}
                        <span className='custom-select-selection-label-prefix-selection' style={selectionLabelStyle()}>{value}</span>
                    </span>
                ) : value);
            }
        } else return placeholder;
    };

    const openOptions = () => {
        setShowOptions(true);
    };

    const closeOptions = () => {
        setShowOptions(false);
    };

    const toggleOptions = () => {
        if (showOptions) closeOptions();
        else openOptions();
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) onClick();
    };

    const handleOptionClick = (value) => {
        setSelectedOptionValue(value);
        if (onChange) onChange(value);
        closeOptions();
    };

    const selectionStyle = () => {
        return {
            border: `2px solid ${isHovering ? (selectionHoverColor ? selectionHoverColor : '#005B80') : '#CCCCCC'}`
        };
    };

    const selectionLabelStyle = () => {
        return {
            color: selectionAccentColor ? selectionAccentColor : '#005B80'
        };
    };

    const renderOptions = () => {
        return options.map((option, index) => (
            <CustomSelectOption
                key={`${id}-custom-select-option-${index}`}
                label={option.label}
                value={option.value}
                customRenderer={option.customRenderer}
                showValueInLabel={showValueInLabel}
                formatLabel={formatLabel}
                hoverColor={optionHoverColor}
                onClick={handleOptionClick}
            />
        ));
    };

    return (
        <div ref={ref} className='custom-select' onClick={handleClick}>
            <div className='custom-select-selection' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={toggleOptions} style={selectionStyle()}>
                <div className='custom-select-selection-label'>{getLabel()}</div>
                <div className='custom-select-selection-icon-container'>
                    <img className='custom-select-selection-icon' src={`${selectIcon}`} alt='Select'/>
                </div>
            </div>
            {showOptions && options && options.length > 0 && (
                <div className='custom-select-options'>
                    {renderOptions()}
                </div>
            )}
        </div>
    );
}

export default CustomSelect;