import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "../../helpers/WithRouter";
import MainLogo from '../../components/MainLogo/MainLogo';
import BannerCarousel from '../../components/BannerCarousel/BannerCarousel';
import DataLoader from '../../components/DataLoader/DataLoader';
import DataError from '../../components/DataError/DataError';
import Category from '../../components/Category/Category';
import BannerExternalUrlModal from "../../components/BannerExternalUrlModal/BannerExternalUrlModal";
import Navigation from '../../config/Navigation';
import Debug from '../../helpers/Debug';
import {Catalog as _Catalog} from "../../models/Catalog";
import {firestore, getCollectionPath} from '../../config/Firebase';
import {collection, where, query, onSnapshot, doc} from 'firebase/firestore';
import {categoryConverter} from '../../models/Category';
import {bannerConverter} from '../../models/Banner';
import './Categories.css';

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            categories: [],
            categoryLoadMap: [],
            loadError: false,
            errorMessage: undefined,
            errorActionLabel: undefined,
            errorAction: undefined,
            showBannerExternalUrlModal: false,
            bannerModalExternalUrl: undefined
        };
        this._isMounted = true;
        this._unsubscribeCategories = [];
        this._unsubscribeSubcategories = [];
        this._unsubscribeBanners = [];
    };

    getMetadata = () => {
        const {client, instance, catalog, store, session, algoliaInsights} = this.props;
        return {client: client, instance: instance, catalog: catalog, store: store, session: session, algoliaInsights: algoliaInsights};
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getCategories();
        this.getBanners();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const needsReload = _Catalog.catalogChanged(prevProps.catalog, this.props.catalog);
        if (needsReload) Navigation.reload();
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    };

    getCategories = () => {
        this.unsubscribeCategories();
        const {catalog} = this.props;
        if (!catalog) {
            this.onLoadError('No se ha encontrado el catálogo especificado', 'Volver a configuración', this.props.onConfigClear);
            return;
        }
        const categories = catalog.categories;
        if (categories) {
            const categoryLoadMap = categories.map(category => ({id: category, isLoading: true, loaded: false}));
            this.changeState({categoryLoadMap: categoryLoadMap}, () => {
                if (categories.length > 0) {
                    categories.forEach((category) => {
                        this.getCategory(category);
                    });
                } else this.onLoadError('El catálogo seleccionado no tiene categorías válidas', 'Volver a configuración', this.props.onConfigClear);
            });
        }
    };

    getCategory = (categoryId) => {
        const _path = getCollectionPath(this.props.client, 'categories');
        const _doc = doc(firestore, _path, categoryId).withConverter(categoryConverter);
        const unsubscribe = onSnapshot(_doc, (_document) => {
            if (_document.exists()) {
                const {categories} = this.state;
                const category = _document.data();
                if (!category.isEmpty && category.isVisible) {
                    const categoryIndex = categories.findIndex(category => category.id === categoryId);
                    if (categoryIndex < 0) categories.push(category);
                    else categories[categoryIndex] = category;
                    this.changeState({categories: categories}, () => this.getSubcategories(categoryId));
                } else this.updateLoadMap(categoryId, false, true);
            } else this.updateLoadMap(categoryId, false, true);
        }, (error) => {
            Debug.printToLog('error', error);
            this.updateLoadMap(categoryId, false, false);
        });
        this._unsubscribeCategories.push(unsubscribe);
    };

    getSubcategories = (categoryId) => {
        const _path = getCollectionPath(this.props.client, 'categories');
        const _collection = collection(firestore, _path);
        const _where1 = where('parents', 'array-contains', categoryId);
        const _where2 = where('is_empty', '==', false);
        const _where3 = where('is_visible', '==', true);
        const _query = query(_collection, _where1, _where2, _where3).withConverter(categoryConverter);
        const unsubscribe = onSnapshot(_query, (_snapshot) => {
            let {categories} = this.state;
            _snapshot.docChanges().forEach((change) => {
                if (['added', 'modified'].includes(change.type)) {
                    const subcategoryId = change.doc.id;
                    const subcategoryData = change.doc.data();
                    if (subcategoryId && subcategoryData) {
                        subcategoryData.parents.forEach((parent) => {
                            const parentMatchIndex = categories.findIndex(category => category.id === parent);
                            if (parentMatchIndex >= 0) {
                                const subcategoryMatchIndex = categories[parentMatchIndex].children.findIndex(subcategory => subcategory.id === subcategoryId);
                                if (subcategoryMatchIndex < 0) categories[parentMatchIndex].children.push(subcategoryData);
                                else categories[parentMatchIndex].children[subcategoryMatchIndex] = subcategoryData;
                            }
                        });
                    }
                }
            });
            this.changeState({categories: categories}, () => {
                this.updateLoadMap(categoryId, false, true);
            });
        }, (error) => {
            Debug.printToLog('error', error);
            this.updateLoadMap(categoryId, false, false);
        });
        this._unsubscribeSubcategories.push(unsubscribe);
    };

    getBanners = () => {
        const _path = getCollectionPath(this.props.client, 'banners');
        const _collection = collection(firestore, _path);
        const _query = query(_collection).withConverter(bannerConverter);
        this._unsubscribeBanners = onSnapshot(_query, (_snapshot) => {
            let {banners} = this.state;
            _snapshot.docChanges().forEach((change) => {
                const id = change.doc.id;
                const data = change.doc.data();
                if (change.type === 'added') {
                    banners.push(data);
                } else if (change.type === 'modified') {
                    const index = banners.findIndex(banner => banner.id === id);
                    if (index >= 0) banners[index] = data;
                } else if (change.type === 'removed') {
                    const index = banners.findIndex(banner => banner.id === id);
                    if (index >= 0) banners.splice(index, 1);
                }
            });
            this.changeState({banners: banners});
        }, (error) => {
            Debug.printToLog('error', error);
            this.changeState({banners: []});
        });
    };

    unsubscribe = () => {
        this.unsubscribeCategories();
        this.unsubscribeSubcategories();
        this.unsubscribeBanners();
    };

    unsubscribeCategories = () => {
        if (this._unsubscribeCategories.length > 0) {
            this._unsubscribeCategories.forEach(unsubscribe => unsubscribe());
            this._unsubscribeCategories = [];
        }
    };

    unsubscribeSubcategories = () => {
        if (this._unsubscribeSubcategories.length > 0) {
            this._unsubscribeSubcategories.forEach(unsubscribe => unsubscribe());
            this._unsubscribeSubcategories = [];
        }
    };

    unsubscribeBanners = () => {
        if (this._unsubscribeBanners) this._unsubscribeBanners();
    };

    updateLoadMap = (categoryId, isLoading, loaded) => {
        const {categoryLoadMap} = this.state;
        const mapIndex = categoryLoadMap.findIndex(category => category.id === categoryId);
        if (mapIndex >= 0) {
            categoryLoadMap[mapIndex].isLoading = isLoading;
            categoryLoadMap[mapIndex].loaded = loaded;
            if (!isLoading && !loaded) this.onLoadError('Ha ocurrido un error al cargar las categorías', 'Reintentar', Navigation.reload);
            this.changeState({categoryLoadMap: categoryLoadMap});
        }
    };

    loadingData = () => {
        let isLoading = false;
        let {categoryLoadMap} = this.state;
        for (let i = 0; i < categoryLoadMap.length; i++) {
            const category = categoryLoadMap[i];
            if (category.isLoading) {
                isLoading = true;
                break;
            }
        }
        return isLoading;
    };

    dataLoaded = () => {
        let loaded = true;
        let {loadError, categoryLoadMap} = this.state;
        if (loadError) return false;
        for (let i = 0; i < categoryLoadMap.length; i++) {
            const category = categoryLoadMap[i];
            if (!category.loaded) {
                loaded = false;
                break;
            }
        }
        return loaded;
    };

    onLoadError = (message, actionLabel, action) => {
        this.changeState({
            categories: [],
            categoryLoadMap: [],
            loadError: true,
            errorMessage: message,
            errorActionLabel: actionLabel,
            errorAction: action
        });
    };

    back = () => {
        this.props.navigate(-1);
    };

    openBannerExternalUrl = (url) => {
        this.changeState({showBannerExternalUrlModal: true, bannerModalExternalUrl: url});
    };

    closeBannerExternalUrl = () => {
        this.changeState({showBannerExternalUrlModal: false, bannerModalExternalUrl: undefined});
    };

    scrollToElement = (target) => {
        const {categories} = this.state;
        const match = categories.find(category => category.id === target);
        if (match) {
            match.children.forEach((child) => {child.focus = true});
            this.changeState({categories: categories});
        }
        const element = document.getElementById(target);
        if (element) element.scrollIntoView({behavior: 'smooth'});
    };

    cancelCategoryFocus = (categoryId, subcategoryId) => {
        const {categories} = this.state;
        const categoryIndex = categories.findIndex(c => c.id === categoryId);
        if (categoryIndex < 0) return;
        const subcategoryIndex = categories[categoryIndex].children.findIndex(s => s.id === subcategoryId);
        if (categoryIndex < 0) return;
        categories[categoryIndex].children[subcategoryIndex].focus = false;
        this.changeState({categories: categories});
    };

    contentWrapperStyle = () => {
        return {
            padding: this.props.inSmallMode ? '40px 0' : '40px 0 40px 90px'
        };
    };

    renderBannerCarousel = () => {
        let style = this.props.style;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const instanceCatalog = this.props.instance ? this.props.instance.catalog : undefined;
        const instanceBanners = this.state.banners.filter((banner) => {
            const bannerCatalogs = banner.catalogs ? banner.catalogs : [];
            return bannerCatalogs.includes(instanceCatalog);
        });
        return (
            <BannerCarousel
                key={`banner-carousel-${instanceBanners.length}`}
                banners={instanceBanners}
                scrollToElement={this.scrollToElement}
                openExternalUrl={this.openBannerExternalUrl}
                dotActiveColor={actionButtonBackgroundColor}
                metadata={this.getMetadata()}
            />
        );
    };

    renderCategories = () => {
        const {style} = this.props;
        const categoriesStyle = style && style['categories'] ? style['categories'] : undefined;
        let {categories} = this.state;
        if (categories.length > 0) {
            const sorted = categories.sort((a, b) => a.order - b.order);
            return sorted.filter(category => category.children.length > 0).map((category) => (
                <Category
                    key={`category-view-${category.id}`}
                    category={category}
                    titleBackgroundColor={categoriesStyle && categoriesStyle['titleBackgroundColor'] ? categoriesStyle['titleBackgroundColor'] : undefined}
                    titleLabelColor={categoriesStyle && categoriesStyle['titleLabelColor'] ? categoriesStyle['titleLabelColor'] : undefined}
                    titleBorderColor={categoriesStyle && categoriesStyle['titleBorderColor'] ? categoriesStyle['titleBorderColor'] : undefined}
                    pulseColor={categoriesStyle && categoriesStyle['pulseColor'] ? categoriesStyle['pulseColor'] : undefined}
                    pulseDuration={8}
                    onPulseFinish={this.cancelCategoryFocus}
                    metadata={this.getMetadata()}
                />
            ));
        } else {
            return (
                <div className='categories-view-no-data'>
                    {this.renderError('No se han encontrado categorías', 'Reintentar', Navigation.reload)}
                </div>
            );
        }
    };

    renderBannerExternalUrl = () => {
        const {style} = this.props;
        const qrScanImage = style && style['categories'] && style['categories']['bannerExternalUrlQrScanImage'] ? style['categories']['bannerExternalUrlQrScanImage'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <BannerExternalUrlModal
                externalUrl={this.state.bannerModalExternalUrl}
                qrScanImage={qrScanImage}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onClose={this.closeBannerExternalUrl}
            />
        );
    };

    renderError = (message, actionLabel, action) => {
        const {style} = this.props;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        return (
            <DataError
                message={message}
                actionLabel={actionLabel}
                action={action}
                actionButtonLabelColor={actionButtonLabelColor}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
            />
        );
    };

    render() {
        return (
            <div className='categories-view'>
                {this.loadingData() && (
                    <div className='categories-view-loader'>
                        <div className='categories-view-loader-wrapper'>
                            <DataLoader message='Cargando categorías disponibles...'/>
                        </div>
                    </div>
                )}
                {!this.loadingData() && !this.dataLoaded() && (
                    <div className='categories-view-error'>
                        <div className='categories-view-error-wrapper'>
                            {this.renderError(this.state.errorMessage, this.state.errorActionLabel, this.state.errorAction)}
                        </div>
                    </div>
                )}
                {!this.loadingData() && this.dataLoaded() && (
                    <React.Fragment>
                        <MainLogo
                            backgroundColor='transparent'
                            backgroundTopPadding={20}
                            backgroundRightPadding={20}
                            backgroundBottomPadding={20}
                            backgroundLeftPadding={20}
                            backgroundWidth='100%'
                            image={this.props.style && this.props.style['global'] ? this.props.style['global']['mainClientLogo'] : undefined}
                            imageAlign='center'
                            imageWidth={undefined}
                            imageHeight={67}
                        />
                        {this.state.banners && this.state.banners.length > 0 && this.renderBannerCarousel()}
                        <div className='categories-view-content-wrapper' style={this.contentWrapperStyle()}>
                            <div className='categories-view-list'>
                                {this.renderCategories()}
                            </div>
                        </div>
                        {this.state.showBannerExternalUrlModal && this.renderBannerExternalUrl()}
                    </React.Fragment>
                )}
            </div>
        );
    };
}

export default withRouter(Categories);

Categories.propTypes = {
    client: PropTypes.string,
    instance: PropTypes.object,
    catalog: PropTypes.object,
    store: PropTypes.object,
    session: PropTypes.object,
    style: PropTypes.object,
    inSmallMode: PropTypes.bool,
    onConfigClear: PropTypes.func,
    algoliaInsights: PropTypes.object
};