import React from 'react';
import './ProductDetailsSidebarTab.css';

function ProductDetailsSidebarTab({id, label, selected, hoverLabelColor, hoverBorderColor, onClick}) {
    const [isHovering, setIsHovering] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        if (onClick) onClick(id);
    };

    const tabStyle = () => {
        return {
            color: selected || isHovering ? (hoverLabelColor ? hoverLabelColor : '#005B80') : '#B2B2B2',
            borderBottom: `solid 5px ${selected ? (hoverBorderColor ? hoverBorderColor : '#005B80') : 'transparent'}`
        };
    };

    return (
        <div className={`product-details-sidebar-tab ${selected ? 'selected' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} style={tabStyle()}>
            {label}
        </div>
    );
}

export default ProductDetailsSidebarTab;