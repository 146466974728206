import React, {useState} from 'react';
import ProductDetailsSidebarTabs from './ProductDetailsSidebarTabs/ProductDetailsSidebarTabs';
import Button from '../Button/Button';
import Activity from '../../helpers/Activity';
import Text from '../../helpers/Text';
import Viewport from '../../helpers/Viewport';
import './ProductDetailsSidebar.css';

function ProductDetailsSidebar({isOpen, product, tabHoverLabelColor, tabHoverBorderColor, cancelButtonBackgroundColor, cancelButtonBorderColor, cancelButtonLabelColor, onClose, metadata}) {
    const viewport = Viewport.dimensions;

    const inSmallMode = () => {
        return viewport.height <= 1400;
    };

    const contentMargin = inSmallMode() ? 0 : 200;
    const tabsHeight = 40;
    const actionsHeight = 80;
    const actionsMargin = 25;

    const hasLongDescription = () => {
        // return Boolean(product.longDescription); // TODO: Activate when the descriptions follows a design standard.
        return false;
    };

    const hasTechnicalData = () => {
        const features = product.visibleFeatures();
        return features && features.length > 0;
    };

    let initialMode = undefined;
    if (hasLongDescription()) initialMode = 'description';
    else if (hasTechnicalData()) initialMode = 'technical';
    const [mode, setMode] = useState(initialMode);

    const getAvailableTabs = () => {
        let tabs = [];
        if (hasLongDescription()) tabs.push({id: 'description', label: 'Descripción'});
        if (hasTechnicalData()) tabs.push({id: 'technical', label: 'Ficha técnica'});
        return tabs;
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'details-sidebar', closeType, 'close', {product_id: product.id, product_name: product.name});
            onClose();
        }
    };

    const changeMode = (newMode) => {
        setMode(newMode);
    };

    const inDescriptionMode = () => {
        return mode === 'description';
    };

    const inTechnicalMode = () => {
        return mode === 'technical';
    };

    const containerStyle = () => {
        return {
            right: isOpen ? 0 : -320,
            opacity: isOpen ? 1 : 0
        };
    };

    const contentStyle = () => {
        const offset = contentMargin + tabsHeight + actionsHeight + actionsMargin;
        return {
            height: `calc(100% - ${offset}px)`,
            maxHeight: inSmallMode() ? 'unset' : 800
        };
    };

    const availableTabs = getAvailableTabs();

    const renderDescription = () => {
        if (product.longDescription) {
            const content = product.longDescription;
            return (
                <div className='product-details-sidebar-description'>
                    <div className='product-details-sidebar-description-content' dangerouslySetInnerHTML={{__html: content}}>
                    </div>
                </div>
            );
        } else return renderError('Este producto no tiene descripción');
    };

    const renderTechnicalData = () => {
        const features = product.getSortedFeatures();
        if (features && features.length > 0) {
            return (
                <div className='product-details-sidebar-features'>
                    {features.map((feature, index) => (
                        <div key={`product-details-sidebar-feature-${product.id}-${index}`} className='product-details-sidebar-feature'>
                            <div className='product-details-sidebar-feature-key'>{feature.name ? Text.capitalizeFirst(feature.name) : 'Desconocido'}</div>
                            <div className='product-details-sidebar-feature-value'>{feature.value ? Text.capitalizeFirst(feature.value) : 'No especificado'}</div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div style={{marginTop: 25}}>
                    <div className='product-details-sidebar-error-message'>Este producto no tiene ficha técnica</div>
                </div>
            );
        }
    };

    const renderError = (message) => {
        return (
            <div className='product-details-sidebar-error'>
                <span className='product-details-sidebar-error-message'>{message}</span>
                {renderActions()}
            </div>
        );
    };

    const renderActions = () => {
        return (
            <div className='product-details-sidebar-actions' style={{height: actionsHeight, marginTop: actionsMargin}}>
                <div className='product-details-sidebar-action'>
                    <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' showPulseEffect={true} onClick={() => handleClose('custom-close-button')}/>
                </div>
            </div>
        );
    };

    return (
        <div className='product-details-sidebar' style={containerStyle()}>
            {(hasLongDescription() || hasTechnicalData()) && (
                <React.Fragment>
                    <div className='product-details-sidebar-tabs-container' style={{marginTop: contentMargin}}>
                    <ProductDetailsSidebarTabs
                            tabs={availableTabs}
                            currentTab={mode}
                            height={tabsHeight}
                            hoverLabelColor={tabHoverLabelColor}
                            hoverBorderColor={tabHoverBorderColor}
                            onClick={changeMode}/>
                    </div>
                    <div className='product-details-sidebar-content' style={contentStyle()}>
                        {inDescriptionMode() && hasLongDescription() && renderDescription()}
                        {inTechnicalMode() && hasTechnicalData() && renderTechnicalData()}
                    </div>
                    {renderActions()}
                </React.Fragment>
            )}
            {!hasLongDescription() && !hasTechnicalData() && renderError('No hay información disponible')}
        </div>
    );
}

export default ProductDetailsSidebar;