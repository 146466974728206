import React from 'react';
import ProductTag from "../ProductBox/ProductTag/ProductTag";
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import CurrencyFormat from 'react-currency-format';
import Currency from '../../config/Currency';
import Viewport from '../../helpers/Viewport';
import defaultFreeDeliveryIcon from "./images/free-delivery.svg";
import defaultDeliveryIcon from "./images/delivery.svg";
import defaultPickupIcon from "./images/pickup.svg";
import './ProductTitle.css';

function ProductTitle({
    product,
    isSidebarOpen,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    freeDeliveryIcon,
    freeDeliveryLabelColor,
    freeDeliveryBackgroundColor,
    deliveryIcon,
    deliveryLabelColor,
    deliveryBackgroundColor,
    pickupIcon,
    pickupLabelColor,
    pickupBackgroundColor
}) {
    const viewport = Viewport.dimensions;
    const currencyFormat = Currency.CLP;

    const getDiscount = () => {
        return product.exclusivePrice ? product.getExclusiveDiscountPercentage() : product.getOfferDiscountPercentage();
    };

    const isSmallScreen = () => {
        return viewport.width <= 800;
    };

    const isSmallScreenWithSidebarOpen = () => {
        return isSidebarOpen && viewport.width <= 1000;
    };

    const containerStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {
                display: 'block',
                flexWrap: 'unset',
                justifyContent: 'unset',
                alignItems: 'unset',
                alignContent: 'unset'
            };
        } else {
            return {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                alignContent: 'center'
            }
        }
    };

    const titleInfoStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {width: '100%'};
        } else {
            return {width: '70%'}
        }
    };

    const pricesStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {width: '100%', marginTop: 30};
        } else {
            return {width: '30%', marginTop: 0}
        }
    };

    const priceStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {justifyContent: 'flex-start'};
        } else {
            return {justifyContent: 'flex-end'};
        }
    };

    const discountStyle = () => {
        return {
            background: discountBackgroundColor || '#005B80',
            color: discountLabelColor || '#FFFFFF'
        };
    };

    const mainPriceStyle = (isExclusivePrice) => {
        const style = priceStyle();
        style.color = isExclusivePrice ? (exclusivePriceLabelColor || '#005B80') : '#414042';
        return style;
    };

    const discount = getDiscount();

    return (
        <div className='product-title' style={containerStyle()}>
            <div className='product-title-info' style={titleInfoStyle()}>
                <div className='product-title-first-line'>
                    {product.freeDelivery && <ProductTag icon={freeDeliveryIcon || defaultFreeDeliveryIcon} label='Despacho gratis' labelColor={freeDeliveryLabelColor} backgroundColor={freeDeliveryBackgroundColor}/>}
                    {!product.freeDelivery && product.delivery && <ProductTag icon={deliveryIcon || defaultDeliveryIcon} label='Despacho' labelColor={deliveryLabelColor} backgroundColor={deliveryBackgroundColor}/>}
                    {product.pickup && <ProductTag icon={pickupIcon || defaultPickupIcon} label='Retiro' labelColor={pickupLabelColor} backgroundColor={pickupBackgroundColor}/>}
                    {discount && discount > 0 ? <div className='product-title-discount-tag' style={discountStyle()}>{discount}%</div> : null}
                    {product.sku && <div className='product-title-sku'>SKU: {product.sku}</div>}
                </div>
                <div className='product-title-second-line'>
                    {product.name && <div className='product-title-name'>{product.name}</div>}
                </div>
            </div>
            <div className='product-title-prices' style={pricesStyle()}>
                {product.normalPrice && ((product.exclusivePrice && product.exclusivePrice !== product.normalPrice) || (product.offerPrice && product.offerPrice !== product.normalPrice)) && (
                    <div className='product-title-irrelevant-price' style={priceStyle()}>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={product.normalPrice}
                            displayType='text'
                        />
                    </div>
                )}
                {product.exclusivePrice && product.offerPrice && (
                    <div className='product-title-secondary-price' style={priceStyle()}>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={product.offerPrice}
                            displayType='text'
                        />
                    </div>
                )}
                {(product.exclusivePrice || product.offerPrice || product.normalPrice) && (
                    <div className='product-title-main-price' style={mainPriceStyle(!!product.exclusivePrice)}>
                        {product.exclusivePrice && (
                            <div className='product-title-price-card'>
                                <ImageWrapper image={exclusivePriceIcon} alt='Card' display='block' width={35} height='auto' loadDelay={0.2}/>
                            </div>
                        )}
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={product.exclusivePrice || product.offerPrice || product.normalPrice}
                            displayType='text'
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductTitle;