import React from 'react';
import Button from '../Button/Button';
import errorImage from './images/error.png';
import './DataError.css';

function DataError({message, action, actionLabel, actionButtonLabelColor, actionButtonBackgroundColor, actionButtonBorderColor}) {
    const handleActionClick = () => {
        if (action) action();
    };

    return (
        <div className='data-error'>
            <img className='data-error-image' src={`${errorImage}`} alt='Error'/>
            <p className='data-error-message'>{message}</p>
            {action && actionLabel && (
                <div className='data-error-actions'>
                    <div className='data-error-action'>
                        <Button label={actionLabel ? actionLabel : ''} labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow={undefined} onClick={handleActionClick}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DataError;