import React, {useState, useEffect, useRef} from 'react';
import ProductLoader from './ProductLoader/ProductLoader';
import ProductTag from './ProductTag/ProductTag';
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import Currency from '../../config/Currency';
import CurrencyFormat from 'react-currency-format';
import noImage from './images/no-image.png';
import defaultDeliveryIcon from './images/delivery.svg';
import defaultFreeDeliveryIcon from './images/free-delivery.svg';
import defaultPickupIcon from './images/pickup.svg';
import default3dIcon from './images/3d.gif';
import defaultFeaturedIcon from './images/star.png';
import './ProductBox.css';

function ProductBox({
    index,
    category,
    product,
    showSku = true,
    showIcons = true,
    active = false,
    _3dIcon,
    featuredIcon,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    offerBackgroundColor,
    offerLabelColor,
    showTags = true,
    deliveryIcon,
    deliveryLabelColor,
    deliveryBackgroundColor,
    freeDeliveryIcon,
    freeDeliveryLabelColor,
    freeDeliveryBackgroundColor,
    pickupIcon,
    pickupLabelColor,
    pickupBackgroundColor,
    visible,
    disabled,
    onClick
}) {
    const wrapperRef = useRef(null);
    const imageRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const currencyFormat = Currency.CLP;
    const loadDelay = 0.2; // Seconds.

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                if (wrapperRef.current) wrapperRef.current.style.transition = `opacity ${loadDelay}s ease`;
                setIsLoadingImage(false);
                setImageLoaded(true);
            }, loadDelay * 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                if (wrapperRef.current) wrapperRef.current.style.transition = `opacity ${loadDelay}s ease`;
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, loadDelay * 1000);
        };
        element.src = getImage();
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            wrapperRef.current.style.transition = 'unset';
            setIsLoadingImage(true);
            setTimeout(() => {element.src = newImage}, loadDelay * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.getMainImage('m')]);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        if (!disabled && onClick) onClick(category, product, index);
    };

    const hasAnyPrice = () => {
        return product.normalPrice || product.offerPrice || product.exclusivePrice;
    };

    const showOfferBanner = () => {
        return Boolean(product.exclusivePrice) && !active;
    };

    const getImage = () => {
        const image = product.getMainImage('m');
        return !image || (!isLoadingImage && !imageLoaded) ? noImage : image;
    };

    const productBoxStyle = () => {
        let height = 575;
        if (!showTags) height -= 65;
        if (!showSku) height -= 15;
        return {
            transform: `scale(${(isLoading || !visible) ? 0 : (isHovering ? 1.03 : 1)})`,
            height: height
        };
    };

    const activeCoverStyle = () => {
        return {
            opacity: active ? 1 : 0
        };
    };

    const productBoxOfferStyle = () => {
        return {
            background: offerBackgroundColor || '#005B80',
            color: offerLabelColor || '#FFFFFF'
        };
    };

    const productBoxWrapperStyle = () => {
        return {
            opacity: isLoadingImage ? 0 : 1
        };
    };

    const productBoxLoaderWrapperStyle = () => {
        return {
            opacity: isLoadingImage ? 1 : 0
        };
    };

    const discountStyle = () => {
        return {
            background: discountBackgroundColor || '#005B80',
            color: discountLabelColor || '#FFFFFF'
        };
    };

    const exclusivePriceStyle = () => {
        return {
            color: exclusivePriceLabelColor || '#005B80'
        };
    };

    const renderAsExclusivePrice = (price, discount) => {
        return (
            <div className='product-box-info-price exclusive' style={exclusivePriceStyle()}>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {exclusivePriceIcon && (
                    <div className='product-box-info-card'>
                        <ImageWrapper image={exclusivePriceIcon} alt='Card' display='block' width={35} height='auto' loadDelay={0.2}/>
                    </div>
                )}
                {discount && discount > 0 ? <div className='product-box-info-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsOfferPrice = (price, discount) => {
        return (
            <div className='product-box-info-price offer'>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {discount && discount > 0 ? <div className='product-box-info-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsNormalPrice = (price) => {
        return (
            <div className='product-box-info-price normal'>
                <span>Antes: </span>
                <div className='product-box-info-normal-price-wrapper'>
                    <CurrencyFormat
                        prefix={currencyFormat.prefix}
                        suffix={currencyFormat.suffix}
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={price}
                        displayType='text'
                    />
                </div>
            </div>
        );
    };

    const name = product.name || 'Sin nombre';

    return (
        <div id={product.id} className='product-box' style={productBoxStyle()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
            <div className='product-box-active-cover' style={activeCoverStyle()}>
                <div className='product-box-top-bar active'>Último visto</div>
            </div>
            {showOfferBanner() && (
                <div className='product-box-top-bar offer' style={productBoxOfferStyle()}>Oferta</div>
            )}
            {showIcons && product.has3dModel && (
                <div className='product-box-3d'>
                    <ImageWrapper image={_3dIcon ? _3dIcon : default3dIcon} alt='Incluye modelo 3D' display='block' width={50} height='auto' loadDelay={0.2}/>
                </div>
            )}
            {showIcons && product.isFeatured && (
                <div className='product-box-featured'>
                    <ImageWrapper image={featuredIcon ? featuredIcon : defaultFeaturedIcon} alt='Destacado' display='block' width={30} height='auto' loadDelay={0.2}/>
                </div>
            )}
            <div ref={wrapperRef} className='product-box-wrapper' style={productBoxWrapperStyle()}>
                <div className='product-box-image-container'>
                    <img ref={imageRef} className='product-box-image' alt={name} loading="lazy"/>
                </div>
                <div className='product-box-info'>
                    {showSku && product.sku && <div className='product-box-info-sku'>SKU: {product.sku}</div>}
                    {product.brand && <div className='product-box-info-brand'>{product.brand}</div>}
                    <div className='product-box-info-name'>{name}</div>
                    {hasAnyPrice() && (
                        <div className='product-box-info-prices'>
                            {product.exclusivePrice && renderAsExclusivePrice(product.exclusivePrice, product.getExclusiveDiscountPercentage())}
                            {product.offerPrice && renderAsOfferPrice(product.offerPrice, product.getOfferDiscountPercentage())}
                            {product.normalPrice && product.normalPrice !== product.offerPrice && (product.offerPrice ? renderAsNormalPrice(product.normalPrice) : renderAsOfferPrice(product.normalPrice, undefined))}
                        </div>
                    )}
                </div>
                {showTags && (
                    <div className='product-box-info-options'>
                        {product.freeDelivery && <ProductTag icon={freeDeliveryIcon || defaultFreeDeliveryIcon} label='Despacho gratis' labelColor={freeDeliveryLabelColor} backgroundColor={freeDeliveryBackgroundColor}/>}
                        {!product.freeDelivery && product.delivery && <ProductTag icon={deliveryIcon || defaultDeliveryIcon} label='Despacho' labelColor={deliveryLabelColor} backgroundColor={deliveryBackgroundColor}/>}
                        {product.pickup && <ProductTag icon={pickupIcon || defaultPickupIcon} label='Retiro' labelColor={pickupLabelColor} backgroundColor={pickupBackgroundColor}/>}
                    </div>
                )}
            </div>
            <div className='product-box-loader' style={productBoxLoaderWrapperStyle()}>
                <ProductLoader/>
            </div>
        </div>
    );
}

export default ProductBox;