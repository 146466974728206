import Debug from './Debug';
import {CLIENT_CHECKOUT_URL, UPDATE_PRODUCT_URL} from '../config/App';
import Environment from "../config/Environment";

export default class WebServices {
    static getClientShoppingCartUrl = (clientId, storeId, shoppingCart) => {
        const environment = Environment.current;
        const url = `${CLIENT_CHECKOUT_URL}?environment=${environment}&client=${clientId}`;
        let body = shoppingCart.toCheckout();
        body.store_id = storeId;
        return fetch(url, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)})
            .then(response => response.json())
            .then(response => {
                if (response) return {id: response['id'], url: response['checkout_url']};
                else return undefined;
            })
            .catch(error => {
                Debug.printToLog('error', error);
                return undefined;
            });
    };

    static asyncProductUpdate = (clientId, productId) => {
        const environment = Environment.current;
        const url = `${UPDATE_PRODUCT_URL}?environment=${environment}&client=${clientId}&persist=true&skus[]=${productId}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => response.json())
            .then(response => {
                return !!response;
            })
            .catch(error => {
                Debug.printToLog('error', error);
                return false;
            });
    };
}