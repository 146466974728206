import React, {useState} from 'react';
import './CustomInput.css';

function CustomInput({id, label, initialValue, placeholder, onFocus, onChange, onAnyKeyPress, onEnterKeyPress}) {
    const [value, setValue] = useState(initialValue || '');

    const handleKeyDown = (e) => {
        if (onAnyKeyPress) onAnyKeyPress();
        if (e.key === 'Enter' && onEnterKeyPress) onEnterKeyPress();
    }

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (onChange) onChange(newValue);
    };

    return (
        <div className='custom-input'>
            {label && <label className='custom-input-label' htmlFor={id}>{label}</label>}
            <input id={id} className='custom-input-value' type='text' placeholder={placeholder} value={value} onFocus={onFocus} onKeyDown={handleKeyDown} onChange={handleChange}/>
        </div>
    );
}

export default CustomInput;