export default class Navigation {
    static rewriteHome = () => {
        const url = Navigation.getHomeUrl();
        window.history.pushState(null, null, url);
    };

    static forceRedirect = (url) => {
        window.location.href = url;
    };

    static reload = () => {
        window.location.reload();
    };

    static getHomeUrl = () => {
        return '/';
    };

    static getCategoryUrl = (categoryId, subcategories, brands) => {
        const hasQueryParameters = (subcategories && subcategories.length > 0) || (brands &&  brands.length > 0);
        let url = `/category/${categoryId}`;
        if (hasQueryParameters) url += '?';
        if (subcategories) subcategories.forEach(subcategory => {url += `categories=${subcategory}&`});
        if (brands) brands.forEach(brand => {url += `brands=${brand}&`});
        if (hasQueryParameters) url = url.substring(0, url.length - 1);
        return url;
    };

    static getProductUrl = (productId, queryId) => {
        let url = `/product/${productId}`;
        if (queryId) url += `/?queryId=${queryId}`;
        return url;
    };

    static getSearchUrl = (query) => {
        return `/search/${encodeURIComponent(query)}`;
    };
}