import React, {useState, useEffect, useRef} from 'react';
import ImageWrapper from '../../ImageWrapper/ImageWrapper';
import Color from '../../../helpers/Color';
import './IconButton.css';

function IconButton({icon, label, labelColor, backgroundColor, defaultAction, action, active, activeIcon, activeLabel, activeLabelColor, activeBackgroundColor, stopPropagation, showPulseEffect, showPulseWhenActive, visible, disabled}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const [pulseEffect, setPulseEffect] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showPulseEffect) startPulseEffect();
        else if (showPulseWhenActive && active) startPulseEffect();
        else stopPulseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPulseEffect, showPulseWhenActive, active, activeBackgroundColor]);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = (event) => {
        if (stopPropagation) event.stopPropagation();
        if (!disabled && action) action();
        if (!disabled && defaultAction) defaultAction();
    };

    const hasIcon = () => {
        return (active && activeIcon) || (!active && icon);
    };

    const getLabel = () => {
        return active ? (activeLabel || label || 'Label') : (label || 'Label');
    };

    const startPulseEffect = () => {
        if (ref) {
            stopPulseEffect();
            const rgb = Color.hexToRgb(activeBackgroundColor) || {r: 0, g: 91, b: 128};
            // noinspection JSUnresolvedReference
            const pulseEffect = ref.current.animate([
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9)`},
                {boxShadow: `0 0 0 10px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`},
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`}
            ], {
                duration: 1500,
                iterations: Infinity
            });
            setPulseEffect(pulseEffect);
        }
    };

    const stopPulseEffect = () => {
        if (pulseEffect) {
            pulseEffect.cancel();
            setPulseEffect(undefined);
        }
    };

    const buttonStyle = () => {
        return {
            background: active ? (activeBackgroundColor || '#005B80') : (backgroundColor || '#FFFFFF'),
            transform: `scale(${isLoading || !visible ? 0 : (isHovering ? 1.1 : 1)})`
        };
    };

    const normalStateStyle = () => {
        return {
            opacity: active ? 0 : 1
        };
    };

    const activeStateStyle = () => {
        return {
            opacity: active ? 1 : 0
        };
    };

    const labelStyle = () => {
        return {
            color: active ? (activeLabelColor || '#FFFFFF') : (labelColor || '#005B80')
        };
    };

    return (
        <div ref={ref} className='icon-button' style={buttonStyle()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
            <div className='icon-button-box-shadow'>
                {hasIcon() && (
                    <div className='icon-button-icon-wrapper'>
                        <div className='icon-button-icon icon-button-icon-normal' style={normalStateStyle()}>
                            <ImageWrapper image={icon} alt={getLabel()} display='block' width={40} height='auto' loadDelay={0.05}/>
                        </div>
                        <div className='icon-button-icon icon-button-icon-active' style={activeStateStyle()}>
                            <ImageWrapper image={activeIcon} alt={getLabel()} display='block' width={40} height='auto' loadDelay={0.05}/>
                        </div>
                    </div>
                )}
                <div className='icon-button-label' style={labelStyle()}>{getLabel()}</div>
            </div>
        </div>
    );
}

export default IconButton;