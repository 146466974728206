import React, {useRef, useState, useEffect} from 'react';
import usePrevious from "../../helpers/UsePrevious";
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import PaymentModalOption from './PaymentModalOption/PaymentModalOption';
import DataLoader from '../DataLoader/DataLoader';
import DataError from '../DataError/DataError';
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import Debug from '../../helpers/Debug';
import Viewport from '../../helpers/Viewport';
import WebServices from "../../helpers/WebServices";
import defaultSmartphoneOptionImage from './images/smartphone-option.png';
import defaultKioskOptionImage from './images/kiosk-option.png';
import defaultSmartphoneOptionQrScanImage from './images/scan.gif';
import hourglassIcon from './images/hourglass.png';
import QRCode from 'qrcode';
import './PaymentModal.css';
import Activity from "../../helpers/Activity";

function PaymentModal({
    client,
    store,
    smartphoneOptionImage,
    kioskOptionImage,
    smartphoneOptionQrScanImage,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    shoppingCart,
    onClose,
    metadata
}) {
    const canvasRef = useRef(null);
    const iframeRef = useRef(null);
    const intervalRef = useRef(null);
    const [isLoadingCheckout, setIsLoadingCheckout] = useState(true);
    const [checkoutLoaded, setCheckoutLoaded] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(undefined);
    const [step, setStep] = useState('start');
    const [error, setError] = useState(false);
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [waitingMode, setWaitingMode] = useState(false);
    const [isInitialIframeLoad, setIsInitialIframeLoad] = useState(true);
    const [iframeIntervalTime, setIframeIntervalTime] = useState(0);
    const previousStep = usePrevious(step);
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 1000, height: 1150};
    const iframeWaitPeriod = 5; // Seconds.

    useEffect(() => {
        WebServices.getClientShoppingCartUrl(client, store.id, shoppingCart)
            .then(response => {
                if (response) {
                    shoppingCart.dbId = response.id;
                    setPaymentUrl(response.url);
                    setCheckoutLoaded(!!response.url);
                    sendAlgoliaInsight();
                } else {
                    setPaymentUrl(undefined);
                    setCheckoutLoaded(false);
                }
                setIsLoadingCheckout(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setError(false);
        switch (step) {
            case 'start':
                if (previousStep === 'kiosk') initializeSelectionMode();
                break;
            case 'smartphone':
                initializeSmartphoneMode();
                break;
            case 'kiosk':
                initializeKioskMode();
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (iframeIntervalTime >= iframeWaitPeriod) stopIframeInterval();
    }, [iframeIntervalTime]);

    const initializeSelectionMode = () => {
        if (!isInitialIframeLoad) startIframeInterval();
    };

    const initializeSmartphoneMode = () => {
        if (paymentUrl) renderQrCode();
        else {
            setError(true);
            setIsLoadingIframe(false);
        }
    };

    const initializeKioskMode = () => {
        if (!paymentUrl) {
            setError(true);
            setIsLoadingIframe(false);
        } else {
            setIsLoadingIframe(true);
            setIframeListeners();
        }
        setIsInitialIframeLoad(false);
    };

    const setIframeListeners = () => {
        const element = iframeRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingIframe(false);
                setIframeLoaded(true);
            }, 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingIframe(false);
                setIframeLoaded(false);
            }, 1000);
        };
        element.src = paymentUrl;
    };

    const startIframeInterval = () => {
        setWaitingMode(true);
        intervalRef.current = setInterval(() => {
            setIframeIntervalTime(iframeIntervalTime => iframeIntervalTime + 1);
        }, 1000);
    };

    const stopIframeInterval = () => {
        setWaitingMode(false);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setIframeIntervalTime(0);
        }
    };

    const isLoading = () => {
        return isLoadingCheckout;
    };

    const dataLoaded = () => {
        return checkoutLoaded;
    };

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handlePaymentOptionClick = (type) => {
        if (['smartphone', 'kiosk'].includes(type))
            Activity.log(metadata, 'checkout', `${type}-option`, `pay-in-${type}`, shoppingCart.toActivityLog());
        setStep(type);
        saveStep(type);
    };

    const handleClose = (closeType) => {
        Activity.log(metadata, 'checkout', closeType, 'close', undefined);
        if (shoppingCart) shoppingCart.dbId = undefined;
        if (onClose) onClose();
    };

    const handleBack = () => {
        Activity.log(metadata, 'checkout', 'back-button', 'back', undefined);
        setStep('start');
    };

    const hasItems = () => {
        return shoppingCart && shoppingCart.items.length > 0;
    };

    const showBackButton = () => {
        return step !== 'start';
    };

    const saveStep = (step) => {
        shoppingCart.updateMode(step);
    };

    const sendAlgoliaInsight = () => {
        if (metadata && metadata.algoliaInsights) {
            const algoliaInsights = metadata.algoliaInsights;
            const items = shoppingCart.items;
            const queryIds = items.map(item => item.queryId);
            const queryId = queryIds.length > 0 ? queryIds[0] : undefined;
            const matches = queryId ? items.filter(item => item.queryId === queryId) : [];
            const sameQueryId = matches.length === items.length;
            const productsIds = items.map(item => item.product);
            if (metadata && metadata.session && metadata.session.id) {
                if (sameQueryId) algoliaInsights.convertedObjectIDsAfterSearch(metadata.session.id, queryId, productsIds);
                else algoliaInsights.convertedObjectIDs(metadata.session.id, productsIds);
            }
        }
    };

    const containerStyle = () => {
        return {
            padding: inSmallMode() && step !== 'kiosk' ? 20 : 0,
            boxSizing: 'border-box'
        };
    };

    const actionsStyle = () => {
        return {
            padding: step === 'kiosk' ? '10px 20px 20px 20px' : 0,
            boxSizing: 'border-box'
        };
    };

    const iframeStyle = () => {
        const show = !isLoadingIframe && iframeLoaded;
        return {
            opacity: show ? 1 : 0
        };
    };

    const iframeLoaderStyle = () => {
        return {
            opacity: isLoadingIframe ? 1 : 0
        };
    };

    const iframeErrorStyle = () => {
        const show = !isLoadingIframe && !iframeLoaded;
        return {
            opacity: show ? 1 : 0,
            width: show ? 'auto' : 0,
            height: show ? 'auto' : 0
        };
    };

    const renderQrCode = () => {
        const canvas = canvasRef.current;
        QRCode.toCanvas(canvas, paymentUrl, {width: 250, height: 250}, (error) => {
            if (error) {
                Debug.printToLog('error', error);
                setError(true);
            }
        });
    };

    const renderStep = () => {
        switch (step) {
            case 'start':
                return renderStartStep();
            case 'smartphone':
                return renderSmartphoneStep();
            case 'kiosk':
                return renderKioskStep();
            default:
                return undefined;
        }
    };

    const renderStartStep = () => {
        return (
            <React.Fragment>
                <p className='payment-modal-title'>Selecciona como quieres completar tus datos y <span className='bold'>recibir tu ticket para pagar en caja</span></p>
                <div className='payment-modal-content' style={{height: inSmallMode() ? 'auto' : 450, padding: 20, overflow: 'auto'}}>
                    <PaymentModalOption
                        type='smartphone'
                        icon={smartphoneOptionImage || defaultSmartphoneOptionImage}
                        label1='Ingreso de datos desde celular y'
                        label2='ticket digital'
                        waitingMode={false}
                        waitingModeIcon={undefined}
                        waitingModeLabel={undefined}
                        onClick={handlePaymentOptionClick}
                    />
                    <PaymentModalOption
                        type='kiosk'
                        icon={kioskOptionImage || defaultKioskOptionImage}
                        label1='Ingreso de datos desde esta pantalla y'
                        label2='ticket impreso'
                        waitingMode={waitingMode}
                        waitingModeIcon={hourglassIcon}
                        waitingModeLabel={`Preparando checkout, ${iframeWaitPeriod - iframeIntervalTime} segundo(s) restantes...`}
                        onClick={handlePaymentOptionClick}
                    />
                </div>
            </React.Fragment>
        );
    };

    const renderSmartphoneStep = () => {
        return (
            <React.Fragment>
                {!error && (
                    <React.Fragment>
                        <p className='payment-modal-title'>
                            <span className='bold'>Ingresa los datos desde tu celular</span>
                        </p>
                        <p className='payment-modal-description'>
                            Escanea el código QR con tu celular y <span className='bold'>completa los datos para obtener tu ticket digital para pagar en caja</span>
                        </p>
                        <div className='payment-modal-content' style={{height: inSmallMode() ? 'auto' : 450, padding: inSmallMode() ? 0 : 20, overflow: 'auto'}}>
                            <div className='payment-modal-image'>
                                <ImageWrapper image={smartphoneOptionQrScanImage || defaultSmartphoneOptionQrScanImage} alt='Scan' display='block' width='100%' height='auto' borderRadius='100%' loadDelay={0.2}/>
                            </div>
                            <div className='payment-modal-qr-image-container'>
                                <canvas ref={canvasRef} className='payment-modal-qr-image-canvas'></canvas>
                                <p className='payment-modal-qr-image-description'>Escanea el código</p>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {error && (
                    <div className='payment-modal-error'>Lo sentimos, se ha producido un error al generar el código QR</div>
                )}
            </React.Fragment>
        );
    };

    const renderKioskStep = () => {
        return (
            <React.Fragment>
                {!error && (
                    <React.Fragment>
                        <p className='payment-modal-title' style={{padding: '20px 20px 30px 20px', margin: 0}}><span className='bold'>¡Comencemos! Ingresa tus datos</span></p>
                        <div className='payment-modal-content' style={{height: 970, padding: 0, overflow: 'unset'}}>
                            <iframe ref={iframeRef} className='payment-modal-iframe' title='Kiosk payment' style={iframeStyle()}/>
                            <div className='payment-modal-iframe-loader' style={iframeLoaderStyle()}>
                                <DataLoader message='Cargando enlace de compra...'/>
                            </div>
                            <div className='payment-moda-iframe-error' style={iframeErrorStyle()}>
                                <DataError message='Se ha producido un error al cargar el enlace de compra'/>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {error && (
                    <div className='payment-modal-error'>Lo sentimos, el enlace de pago no es válido</div>
                )}
            </React.Fragment>
        );
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={step === 'kiosk' ? 0 : 40} width={1000} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            {isLoading() && (
                <div className='payment-modal-loader' style={containerStyle()}>
                    <DataLoader message='Iniciando interfaz de pago...'/>
                </div>
            )}
            {!isLoading() && !dataLoaded() && (
                <div className='payment-modal-error' style={containerStyle()}>
                    <DataError
                        message='Se ha producido un error al iniciar el proceso de checkout'
                        actionLabel='Cerrar'
                        action={() => handleClose('checkout-error-close')}
                        actionButtonLabelColor={actionButtonLabelColor}
                        actionButtonBackgroundColor={actionButtonBackgroundColor}
                        actionButtonBorderColor={actionButtonBorderColor}
                    />
                </div>
            )}
            {!isLoading() && dataLoaded() && (
                <React.Fragment>
                    {!hasItems() && (
                        <div className='payment-modal-error' style={containerStyle()}>
                            <DataError
                                message='No hay productos en el carro de compras'
                                actionLabel='Cerrar'
                                action={() => handleClose('empty-cart-close')}
                                actionButtonLabelColor={actionButtonLabelColor}
                                actionButtonBackgroundColor={actionButtonBackgroundColor}
                                actionButtonBorderColor={actionButtonBorderColor}
                            />
                        </div>
                    )}
                    {hasItems() && (
                        <div className='payment-modal' style={containerStyle()}>
                            {hasItems() && renderStep()}
                            <div className='payment-modal-actions' style={actionsStyle()}>
                                <div className='payment-modal-action'>
                                    <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} borderWidth={1} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={() => handleClose('custom-close-button')}/>
                                </div>
                                {showBackButton() && (
                                    <div className='payment-modal-action'>
                                        <Button label='Volver' labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} borderWidth={1} minWidth='auto' maxWidth='100%' onClick={handleBack}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </Modal>
    );
}

export default PaymentModal;