import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '../../helpers/WithRouter';
import ImageWrapper from '../ImageWrapper/ImageWrapper';
import BannerCarouselDot from './BannerCarouselDot/BannerCarouselDot';
import HTMLElement from '../../helpers/HTMLElement';
import Navigation from '../../config/Navigation';
import Activity from '../../helpers/Activity';
import Viewport from '../../helpers/Viewport';
import './BannerCarousel.css';

class BannerCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 0,
            slideInterval: 5000,
            slideDuration: 500,
            slideDelta: 40,
            blockLimits: false
        };
        this._isMounted = true;
        this._interval = undefined;
        this._isDragging = false;
        this._dragStartX = undefined;
        this._manualSlide = false;
        this._containerRef = React.createRef();
        this._wrapperRef = React.createRef();
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        if (this.allowSlide()) {
            this.addTransition();
            this.createInterval();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.allowSlide() && prevState.slideIndex !== this.state.slideIndex) {
            if (!this.state.blockLimits && this.state.slideIndex < 0) {
                this.onLeftLimit();
            }
            if (!this.state.blockLimits && this.state.slideIndex === this.props.banners.length) {
                this.onRightLimit();
            }
            if (prevState.slideIndex === this.props.banners.length && this.state.slideIndex === 0) {
                setTimeout(() => {
                    this.addTransition();
                }, 100);
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.destroyInterval();
    };

    allowSlide = () => {
        return this.props.banners.length > 1;
    };

    createInterval = () => {
        if (this.allowSlide()) {
            this.destroyInterval();
            this._interval = setInterval(() => {
                this.changeState({slideIndex: this.state.slideIndex + 1});
            }, this.state.slideInterval);
        }
    };

    destroyInterval = () => {
        if (this._interval) clearInterval(this._interval);
    };

    addTransition = () => {
        if (this.allowSlide() && this._wrapperRef && this._wrapperRef.current) this._wrapperRef.current.style.transition = `transform ${this.state.slideDuration / 1000}s ease`;
    };

    removeTransition = () => {
        if (this._wrapperRef && this._wrapperRef.current) this._wrapperRef.current.style.transition = 'unset';
    };

    onLeftLimit = () => {
        if (this.allowSlide()) {
            this.changeState({blockLimits: true}, () => {
                this.removeTransition();
                this.changeState({slideIndex: this.props.banners.length}, () => {
                    setTimeout(() => {
                        this.addTransition();
                        this.changeState({slideIndex: this.props.banners.length - 1}, () => {
                            this.changeState({blockLimits: false});
                        });
                    }, 100);
                });
            });
        }
    };

    onRightLimit = () => {
        if (this.allowSlide()) {
            this.changeState({blockLimits: true}, () => {
                setTimeout(() => {
                    this.removeTransition();
                    this.changeState({slideIndex: 0}, () => {
                        this.changeState({blockLimits: false});
                    });
                }, this.state.slideDuration);
            });
        }
    };

    handleClick = (index) => {
        const {banners, metadata, navigate, scrollToElement, openExternalUrl} = this.props;
        const banner = banners[index];
        if (banner) {
            if (banner.linkType && banner.linkType !== 'none' && banner.linkTarget)
                Activity.log(metadata, 'banner', banner.id, 'click', {
                    link_type: banner.linkType,
                    link_target: banner.linkTarget,
                    link_options: banner.linkOptions || null
                });
            switch (banner.linkType) {
                case 'item':
                    const productId = banner.linkTarget;
                    if (productId) {
                        if (metadata && metadata.session && metadata.session.id) metadata.algoliaInsights.clickedObjectIDs(metadata.session.id, [productId]);
                        const url = Navigation.getProductUrl(productId);
                        navigate(url);
                    }
                    break;
                case 'category':
                    const categoryId = banner.linkTarget;
                    let subcategories = [];
                    let brands = [];
                    if (banner.linkOptions) {
                        if (banner.linkOptions.categories && banner.linkOptions.categories.length > 0)
                            subcategories = banner.linkOptions.categories;
                        if (banner.linkOptions.filters) {
                            // noinspection JSUnresolvedReference
                            if (banner.linkOptions.filters.brands && banner.linkOptions.filters.brands.length > 0)
                                // noinspection JSUnresolvedReference
                                brands = banner.linkOptions.filters.brands;
                        }
                    }
                    if (categoryId) {
                        const url = Navigation.getCategoryUrl(categoryId, subcategories, brands);
                        navigate(url);
                    }
                    break;
                case 'anchor':
                    const elementId = banner.linkTarget;
                    if (elementId && scrollToElement) scrollToElement(elementId);
                    break;
                case 'external':
                    const externalUrl = banner.linkTarget;
                    if (externalUrl) openExternalUrl(externalUrl);
                    break;
                case "search":
                    const query = banner.linkTarget;
                    if (query) {
                        const url = Navigation.getSearchUrl(query);
                        navigate(url);
                    }
                    break;
                case 'none':
                    break;
                default:
                    break;
            }
        }
    };

    wrapperStyle = () => {
        return {
            transform: `translateX(-${this.state.slideIndex * 100}%)`
        };
    };

    onDragStart = (event) => {
        const dragStartX = event.pageX !== undefined ? event.pageX : event.touches[0].pageX;
        this._isDragging = true;
        this._dragStartX = dragStartX;
        this.destroyInterval();
    };

    onDrag = (event) => {
        if (this.allowSlide() && this._isDragging) {
            const viewport = Viewport.dimensions;
            const currentPositionX = event.pageX !== undefined ? event.pageX : event.touches[0].pageX;
            const bannerWidth = this._containerRef && this._containerRef.current ? HTMLElement.getDisplayProperties(this._containerRef.current).width : viewport.width;
            const dragStartPercent = (this._dragStartX * 100) / bannerWidth;
            const currentPositionPercent = (currentPositionX * 100) / bannerWidth;
            const difference = currentPositionPercent - dragStartPercent;
            if (Math.abs(difference) > Math.abs(this.state.slideDelta)) {
                const {metadata} = this.props;
                this._manualSlide = true;
                this._isDragging = false;
                let newSlideIndex;
                let targetBanner;
                if (difference > 0) { // Slide to left.
                    newSlideIndex = this.state.slideIndex - 1;
                    targetBanner = this.props.banners[newSlideIndex < 0 ? this.props.banners.length - 1 : newSlideIndex];
                    this.changeState({slideIndex: newSlideIndex});
                } else if (difference < 0) { // Slide to right.
                    newSlideIndex = this.state.slideIndex + 1;
                    targetBanner = this.props.banners[newSlideIndex === this.props.banners.length ? 0 : newSlideIndex];
                }
                this.changeState({slideIndex: newSlideIndex}, () => {
                    Activity.log(metadata, 'banner', targetBanner.id, 'slide', undefined);
                });
            }
        }
    };

    onDragEnd = (preventAction) => {
        const {slideIndex} = this.state;
        if (!preventAction && !this._manualSlide) this.handleClick(slideIndex);
        this._isDragging = false;
        this._dragStartX = undefined;
        this._manualSlide = false;
        this.createInterval();
    };

    handleDotClick = (index) => {
        this.destroyInterval();
        this.changeState({slideIndex: index}, () => {
            const {banners, metadata} = this.props;
            const targetBanner = banners[index];
            Activity.log(metadata, 'banner', targetBanner.id, 'slide', undefined);
            setTimeout(() => {
                this.createInterval();
            }, this.state.slideDuration);
        });
    };

    renderBanners = () => {
        const {banners} = this.props;
        if (banners.length > 0) {
            let drawableBanners = [];
            if (banners.length > 1) {
                drawableBanners = banners.map(banner => banner);
                drawableBanners.push(banners[0]);
            } else drawableBanners.push(banners[0]);
            return drawableBanners.map((banner, index) => (
                <div key={`banner-${banner.id}-${index}`} className='banner-container'>
                    <ImageWrapper display='block' image={banner.image} alt={`banner-${index}`} width='100%' height='auto' borderRadius={0} loadDelay={0} transition={false}/>
                </div>
            ));
        } else return undefined;
    };

    renderDots = () => {
        return this.props.banners.map((banner, index) => (
            <BannerCarouselDot
                key={`banner-carousel-dot-${banner.id}-${index}`}
                index={index}
                active={this.state.slideIndex === index}
                activeColor={this.props.dotActiveColor}
                onClick={this.handleDotClick}
            />
        ));
    };

    render() {
        return (
            <div className='banner-carousel-container'>
                <div
                    ref={this._containerRef}
                    className='banner-carousel'
                    onMouseDown={this.onDragStart}
                    onTouchStart={this.onDragStart}
                    onMouseMove={this.onDrag}
                    onTouchMove={this.onDrag}
                    onMouseUp={() => {
                        this.onDragEnd(false)
                    }}
                    onTouchEnd={() => {
                        this.onDragEnd(false)
                    }}
                    onMouseLeave={() => {
                        this.onDragEnd(true)
                    }}
                >
                    <div ref={this._wrapperRef} className='banner-carousel-wrapper' style={this.wrapperStyle()}>
                        {this.renderBanners()}
                    </div>
                </div>
                {this.props.banners.length > 1 && (
                    <div className='banner-carousel-dots'>
                        <div className='banner-carousel-dots-wrapper'>
                            {this.renderDots()}
                        </div>
                    </div>
                )}
            </div>
        );
    };
}

BannerCarousel.propTypes = {
    banners: PropTypes.array,
    scrollToElement: PropTypes.func,
    openExternalUrl: PropTypes.func,
    dotActiveColor: PropTypes.string,
    metadata: PropTypes.object
};

export default withRouter(BannerCarousel);