import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '../../helpers/WithRouter';
import DataLoader from '../../components/DataLoader/DataLoader';
import DataError from '../../components/DataError/DataError';
import ProductTitle from '../../components/ProductTitle/ProductTitle';
import ProductBoxSlider from '../../components/ProductBoxSlider/ProductBoxSlider';
import ImageList from '../../components/ImageList/ImageList';
import ImageViewer from '../../components/ImageViewer/ImageViewer';
import ModelViewer from '../../components/ModelViewer/ModelViewer';
import IconButtonsGrid from '../../components/IconButtonsGrid/IconButtonsGrid';
import ARModal from '../../components/ARModal/ARModal';
import ProductDetailsSidebar from '../../components/ProductDetailsSidebar/ProductDetailsSidebar';
import ShareModal from '../../components/ShareModal/ShareModal';
import Modal from "../../components/Modal/Modal";
import NotFound from '../NotFound/NotFound';
import Navigation from '../../config/Navigation';
import Viewport from '../../helpers/Viewport';
import Debug from '../../helpers/Debug';
import Activity from '../../helpers/Activity';
import URLParams from '../../helpers/URLParams';
import WebServices from "../../helpers/WebServices";
import {Catalog as _Catalog} from '../../models/Catalog';
import {Product as _Product} from '../../models/Product';
import {ShoppingCartItem as _ShoppingCartItem} from '../../models/ShoppingCart';
import {getRecommendedItems} from "../../config/AlgoliaSearch";
import {firestore, getCollectionPath} from '../../config/Firebase';
import {doc, onSnapshot} from 'firebase/firestore';
import {productConverter} from '../../models/Product';
import hideBlueIcon from './images/eye-blue.svg';
import showWhiteIcon from './images/eye-white.svg';
import arBlueIcon from './images/ar-blue.svg';
import arWhiteIcon from './images/ar-white.svg';
import rulerBlueIcon from './images/ruler-blue.svg';
import rulerWhiteIcon from './images/ruler-white.svg';
import detailsBlueIcon from './images/details-blue.svg';
import detailsWhiteIcon from './images/details-white.svg';
import shareBlueIcon from './images/share-blue.svg';
import shareWhiteIcon from './images/share-white.svg';
import cartWhiteIcon from './images/cart-white.svg';
import cartBlueIcon from './images/cart-blue.svg';
import caretIcon from './images/caret.png';
import './Product.css'

class Product extends React.Component {
    constructor(props) {
        super(props);
        const parameters = URLParams.getParams();
        const queryId = parameters.get('queryId');
        this.state = {
            queryId: queryId || undefined,
            productId: this.props.params.productId,
            isLoadingProduct: true,
            productLoaded: false,
            product: undefined,
            similar: [],
            maxSimilar: 10,
            similarDeepLevel: 2,
            listMode: 'images',
            selectedImageIndex: '0',
            hideDetails: false,
            showArModal: false,
            showSize: false,
            showProductDetails: false,
            showShareModal: false,
            showCart: false,
            showPaymentModal: false,
            showActions: false,
            notFound: false,
            errorMessage: undefined,
            errorActionLabel: undefined,
            errorAction: undefined
        };
        this._isMounted = true;
        this._iframeRef = React.createRef();
        this._unsubscribeProduct = undefined;
    };

    getMetadata = () => {
        const {client, instance, catalog, store, session, algoliaInsights} = this.props;
        return {client: client, instance: instance, catalog: catalog, store: store, session: session, algoliaInsights: algoliaInsights};
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.updateProduct();
        this.getProduct();
        this.getSimilar();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const needsReload = _Catalog.catalogChanged(prevProps.catalog, this.props.catalog);
        if (needsReload) Navigation.reload();
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    };

    getProduct = () => {
        const _path = getCollectionPath(this.props.client, 'items');
        const _doc = doc(firestore, _path, this.state.productId).withConverter(productConverter);
        this._unsubscribeProduct = onSnapshot(_doc, (_document) => {
            if (_document.exists()) {
                const {catalog} = this.props;
                const categories = catalog ? catalog.categories : [];
                const product = _document.data();
                let inCatalog = false;
                product.categories.forEach((category) => {
                    const exists = categories.includes(category);
                    if (exists) inCatalog = true;
                });
                if (!inCatalog) this.onLoadError(true, undefined, 'Volver', this.back);
                else {
                    const {isLoadingProduct, productLoaded} = this.state;
                    const has3dModel = product.has3dModel;
                    const isFirstLoad = isLoadingProduct && !productLoaded;
                    this.changeState({product: product}, () => {
                        if (isFirstLoad) this.changeState({isLoadingProduct: false, productLoaded: true, selectedImageIndex: has3dModel ? '3D' : '0'});
                    });
                }
            } else this.onLoadError(true, undefined, 'Volver', this.back);
        }, (error) => {
            Debug.printToLog('error', error);
            this.onLoadError(false, 'Se ha producido un error al cargar la información del producto', 'Reintentar', Navigation.reload);
        });
    };

    getSimilar = () => {
        const {client} = this.props;
        const {productId} = this.state;
        getRecommendedItems(client, 'looking-similar', productId, 60, 30)
            .then((response) => {
                if (response && response.length > 0) this.changeState({similar: response});
            });
    };

    getFilteredSimilar = () => {
        const {product, similar, maxSimilar, similarDeepLevel} = this.state;
        const sameCategory = _Product.getSameCategoryProducts(product, similar, similarDeepLevel);
        return _Product.getSimilarByPriceAndRelevance(product, sameCategory, maxSimilar);
    };

    updateProduct = () => {
        WebServices.asyncProductUpdate(this.props.client, this.state.productId);
    };

    unsubscribe = () => {
        if (this._unsubscribeProduct) this._unsubscribeProduct();
    };

    loadingData = () => {
        return this.state.isLoadingProduct;
    };

    dataLoaded = () => {
        return this.state.productLoaded;
    };

    onLoadError = (notFound, message, actionLabel, action) => {
        this.changeState({
            isLoadingProduct: false,
            productLoaded: false,
            product: undefined,
            notFound: notFound,
            errorMessage: message,
            errorActionLabel: actionLabel,
            errorAction: action
        });
    };

    back = () => {
        this.props.navigate(-1);
    };

    getIconButtons = () => {
        const {style} = this.props;
        const {hideDetails} = this.state;
        const displayStyle = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['display'] ? style['product']['actionButtons']['display'] : {};
        const arStyle = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['ar'] ? style['product']['actionButtons']['ar'] : {};
        const rulerStyle = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['ruler'] ? style['product']['actionButtons']['ruler'] : {};
        const shareStyle = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['share'] ? style['product']['actionButtons']['share'] : {};
        const detailsStyle = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['details'] ? style['product']['actionButtons']['details'] : {};
        const cartStyle = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['cart'] ? style['product']['actionButtons']['cart'] : {};
        const visibleFeatures = this.state.product.visibleFeatures();
        let buttons = [];
        const showDisplayButton   = true;
        const showArButton        = this.state.selectedImageIndex === '3D' && Boolean(this.state.product.viewItArUrl);
        const showRulerButton     = this.state.selectedImageIndex === '3D' && Boolean(this.state.product.viewItSceneUrl);
        const showShareButton     = this.state.selectedImageIndex === '3D' && Boolean(this.state.product.viewItSceneUrl);
        const hideDetailsButton   = Boolean(visibleFeatures && visibleFeatures.length > 0); // If in the future the long description is necessary, add it to the condition.
        const showAddToCartButton = true;
        const displayButton   = {icon: displayStyle['inactiveIcon']  || hideBlueIcon,    label: 'Ocultar Detalles',  labelColor: displayStyle['labelColor'],  backgroundColor: displayStyle['backgroundColor'],  action: this.toggleDetails,      active: this.state.hideDetails,        activeIcon: displayStyle['activeIcon']  || showWhiteIcon,     activeLabel: 'Mostrar Detalles', activeLabelColor: displayStyle['activeLabelColor'],  activeBackgroundColor: displayStyle['activeBackgroundColor'],  stopPropagation: false, forceEnabled: true,  showPulseEffect: false,        showPulseWhenActive: true };
        const arButton        = {icon: arStyle['inactiveIcon']       || arBlueIcon,      label: 'Ver en tu espacio', labelColor: arStyle['labelColor'],       backgroundColor: arStyle['backgroundColor'],       action: this.openArModal,        active: this.state.showArModal,        activeIcon: arStyle['activeIcon']       || arWhiteIcon,       activeLabel: undefined,          activeLabelColor: arStyle['activeLabelColor'],       activeBackgroundColor: arStyle['activeBackgroundColor'],       stopPropagation: false, forceEnabled: false, showPulseEffect: false,        showPulseWhenActive: false};
        const rulerButton     = {icon: rulerStyle['inactiveIcon']    || rulerBlueIcon,   label: 'Medidas',           labelColor: rulerStyle['labelColor'],    backgroundColor: rulerStyle['backgroundColor'],    action: this.toggleSize,         active: this.state.showSize,           activeIcon: rulerStyle['activeIcon']    || rulerWhiteIcon,    activeLabel: undefined,          activeLabelColor: rulerStyle['activeLabelColor'],    activeBackgroundColor: rulerStyle['activeBackgroundColor'],    stopPropagation: false, forceEnabled: false, showPulseEffect: false,        showPulseWhenActive: false};
        const shareButton     = {icon: shareStyle['inactiveIcon']    || shareBlueIcon,   label: 'Compartir',         labelColor: shareStyle['labelColor'],    backgroundColor: shareStyle['backgroundColor'],    action: this.openShareModal,     active: this.state.showShareModal,     activeIcon: shareStyle['activeIcon']    || shareWhiteIcon,    activeLabel: undefined,          activeLabelColor: shareStyle['activeLabelColor'],    activeBackgroundColor: shareStyle['activeBackgroundColor'],    stopPropagation: false, forceEnabled: false, showPulseEffect: false,        showPulseWhenActive: false};
        const detailsButton   = {icon: detailsStyle['inactiveIcon']  || detailsBlueIcon, label: 'F. Técnica',        labelColor: detailsStyle['labelColor'],  backgroundColor: detailsStyle['backgroundColor'],  action: this.openProductDetails, active: this.state.showProductDetails, activeIcon: detailsStyle['activeIcon']  || detailsWhiteIcon,  activeLabel: undefined,          activeLabelColor: detailsStyle['activeLabelColor'],  activeBackgroundColor: detailsStyle['activeBackgroundColor'],  stopPropagation: false, forceEnabled: false, showPulseEffect: false,        showPulseWhenActive: false};
        const addToCartButton = {icon: cartStyle['inactiveIcon']     || cartBlueIcon,    label: 'Agregar al carro',  labelColor: cartStyle['labelColor'],     backgroundColor: cartStyle['backgroundColor'],     action: this.addItemToCart,      active: false,                         activeIcon: cartStyle['activeIcon']     || cartWhiteIcon,     activeLabel: undefined,          activeLabelColor: cartStyle['activeLabelColor'],     activeBackgroundColor: cartStyle['activeBackgroundColor'],     stopPropagation: false, forceEnabled: false, showPulseEffect: !hideDetails, showPulseWhenActive: false};
        if (showAddToCartButton) buttons.push(addToCartButton);
        if (showArButton)        buttons.push(arButton);
        if (hideDetailsButton)   buttons.push(detailsButton);
        if (showRulerButton)     buttons.push(rulerButton);
        if (showDisplayButton)   buttons.push(displayButton);
        if (showShareButton)     buttons.push(shareButton);
        return buttons;
    };

    buildModelViewerUrl = () => {
        const {product, showSize} = this.state;
        let url = product.viewItSceneUrl;
        if (url) {
            url += '&showDimensions=false';
            url += '&showAr=false';
            url += '&showProductLink=false';
            url += '&showTryOnLink=false';
            url += '&showShare=false';
            url += `&drawDimensions=${showSize}`;
        }
        return url;
    };

    changeListMode = (mode) => {
        const {product} = this.state;
        const metadata = this.getMetadata();
        Activity.log(metadata, 'subelements-list', `${mode}-button`, 'click', {product_id: product.id, product_name: product.name, mode: mode});
        this.changeState({listMode: mode});
    };

    changeImage = (id) => {
        const {product} = this.state;
        const metadata = this.getMetadata();
        Activity.log(metadata, 'image-gallery', `button-${id}`, 'click', {product_id: product.id, product_name: product.name, selected_id: id});
        this.changeState({selectedImageIndex: id});
    };

    toSimilarProduct = (category, product) => {
        const {algoliaInsights} = this.props;
        const metadata = this.getMetadata();
        Activity.log(metadata, 'similar-product', product.id, 'click', {
            from: 'similar-list',
            source_product: {product_id: this.state.product.id, product_name: this.state.product.name},
            similar_product: {product_id: product.id, product_name: product.name}
        });
        if (metadata && metadata.session && metadata.session.id) algoliaInsights.clickedObjectIDs(metadata.session.id, [product.id]);
        const url = Navigation.getProductUrl(product.id, undefined);
        Navigation.forceRedirect(url);
    };

    openActions = () => {
        const metadata = this.getMetadata();
        const {product} = this.state;
        this.changeState({showActions: true});
        Activity.log(metadata, 'icon-buttons-bar', 'toggle-button', 'open', {product_id: product.id, product_name: product.name});
    };

    closeActions = (closeType) => {
        const metadata = this.getMetadata();
        const {product} = this.state;
        this.changeState({showActions: false});
        if (closeType !== 'silent')
            Activity.log(metadata, 'icon-buttons-bar', closeType, 'close', {product_id: product.id, product_name: product.name});
    };

    toggleDetails = () => {
        const metadata = this.getMetadata();
        const {product} = this.state;
        const newHideDetails = !this.state.hideDetails;
        this.changeState({hideDetails: newHideDetails});
        if (newHideDetails) {
            if (this.props.closeActionBar) this.props.closeActionBar();
            if (this.props.closeActionBarMobile) this.props.closeActionBarMobile('silent');
        } else {
            if (this.props.openActionBar) this.props.openActionBar();
        }
        Activity.log(metadata, 'icon-button', 'toggle-ui-button', newHideDetails ? 'hide' : 'show', {product_id: product.id, product_name: product.name});
    };

    openArModal = () => {
        const {product} = this.state;
        const metadata = this.getMetadata();
        Activity.log(metadata, 'icon-button', 'ar-button', 'open', {product_id: product.id, product_name: product.name});
        this.changeState({showArModal: true});
    };

    closeArModal = () => {
        this.changeState({showArModal: false});
    };

    toggleSize = () => {
        const {product} = this.state;
        const metadata = this.getMetadata();
        const showSize = !this.state.showSize;
        this.changeState({showSize: showSize});
        if (this._iframeRef && this._iframeRef.current) {
            this._iframeRef.current.contentWindow.postMessage({from: "kiosk", type: `${showSize ? 'show' : 'hide'}-dimensions`, data: {}}, "*");
        }
        Activity.log(metadata, 'icon-button', 'toggle-size-button', showSize ? 'show' : 'hide', {product_id: product.id, product_name: product.name});
    };

    openShareModal = () => {
        const {product} = this.state;
        const metadata = this.getMetadata();
        this.changeState({showShareModal: true});
        Activity.log(metadata, 'icon-button', 'share-button', 'open', {product_id: product.id, product_name: product.name});
    };

    closeShareModal = () => {
        this.changeState({showShareModal: false});
    };

    openProductDetails = () => {
        const {product} = this.state;
        const metadata = this.getMetadata();
        Activity.log(metadata, 'icon-button', 'details-button', 'open', {product_id: product.id, product_name: product.name});
        this.changeState({showProductDetails: true});
    };

    closeProductDetails = () => {
        this.changeState({showProductDetails: false});
    };

    addItemToCart = () => {
        const {queryId, product} = this.state;
        const {algoliaInsights} = this.props;
        const id = `${this.props.client}-${product.id}`;
        const item = new _ShoppingCartItem({
            id: id,
            product: product.id,
            name: product.name,
            quantity: 1,
            minQuantity: 1,
            maxQuantity: product.maxPerItem,
            normalPrice: product.normalPrice,
            offerPrice: product.offerPrice,
            exclusivePrice: product.exclusivePrice,
            queryId: queryId
        });
        if (this.props.addItemToCart) {
            this.props.addItemToCart(item)
                .then(() => {
                    const metadata = this.getMetadata();
                    Activity.log(metadata, 'icon-buttons-bar', 'add-to-cart-button', 'add', item.toActivityLog(1));
                    const minPrice = product.minPrice();
                    const maxPrice = product.maxPrice();
                    const data = {price: minPrice, discount: maxPrice - minPrice, quantity: 1};
                    if (metadata && metadata.session && metadata.session.id) {
                        if (queryId) algoliaInsights.addedToCartObjectIDsAfterSearch(metadata.session.id, queryId, [product.id], [data]);
                        else algoliaInsights.addedToCartObjectIDs(metadata.session.id, [product.id], [data]);
                    }
                    if (this.props.openShoppingCart) this.props.openShoppingCart();
                });
        }
    };

    contentStyle = () => {
        const viewport = Viewport.dimensions;
        const {showProductDetails} = this.state;
        return {
            width: showProductDetails ? 'calc(100% - 320px)' : '100%',
            height: viewport.height
        };
    };

    listStyle = () => {
        const {showProductDetails, hideDetails} = this.state;
        return {
            width: showProductDetails ? 'calc(100% - 320px)' : '100%',
            transition: 'width 0.5s ease',
            pointerEvents: hideDetails ? 'none' : 'auto'
        };
    };

    renderTitle = () => {
        const {style} = this.props;
        const discountBackgroundColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountBackgroundColor'] ? style['global']['prices']['discountBackgroundColor'] : undefined;
        const discountLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountLabelColor'] ? style['global']['prices']['discountLabelColor'] : undefined;
        const exclusivePriceLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceLabelColor'] ? style['global']['prices']['exclusivePriceLabelColor'] : undefined;
        const exclusivePriceIcon = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceIcon'] ? style['global']['prices']['exclusivePriceIcon'] : undefined;
        const freeDeliveryIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['icon'] ? style['global']['tags']['freeDelivery']['icon'] : undefined;
        const freeDeliveryLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['labelColor'] ? style['global']['tags']['freeDelivery']['labelColor'] : undefined;
        const freeDeliveryBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['backgroundColor'] ? style['global']['tags']['freeDelivery']['backgroundColor'] : undefined;
        const deliveryIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['icon'] ? style['global']['tags']['delivery']['icon'] : undefined;
        const deliveryLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['labelColor'] ? style['global']['tags']['delivery']['labelColor'] : undefined;
        const deliveryBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['backgroundColor'] ? style['global']['tags']['delivery']['backgroundColor'] : undefined;
        const pickupIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['icon'] ? style['global']['tags']['pickup']['icon'] : undefined;
        const pickupLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['labelColor'] ? style['global']['tags']['pickup']['labelColor'] : undefined;
        const pickupBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['backgroundColor'] ? style['global']['tags']['pickup']['backgroundColor'] : undefined;
        return (
            <div className={`product-view-product-title ${this.state.hideDetails ? 'hidden' : 'visible'}`}>
                <ProductTitle
                    product={this.state.product}
                    isSidebarOpen={this.state.showProductDetails}
                    discountBackgroundColor={discountBackgroundColor}
                    discountLabelColor={discountLabelColor}
                    exclusivePriceLabelColor={exclusivePriceLabelColor}
                    exclusivePriceIcon={exclusivePriceIcon}
                    freeDeliveryIcon={freeDeliveryIcon}
                    freeDeliveryLabelColor={freeDeliveryLabelColor}
                    freeDeliveryBackgroundColor={freeDeliveryBackgroundColor}
                    deliveryIcon={deliveryIcon}
                    deliveryLabelColor={deliveryLabelColor}
                    deliveryBackgroundColor={deliveryBackgroundColor}
                    pickupIcon={pickupIcon}
                    pickupLabelColor={pickupLabelColor}
                    pickupBackgroundColor={pickupBackgroundColor}
                />
            </div>
        );
    };

    renderList = (showScrollbar, type) => {
        const {listMode, hideDetails} = this.state;
        const similar = this.getFilteredSimilar();
        return (
            <div className='product-view-product-list'>
                {similar.length > 0 && (
                    <div className='product-view-product-tabs'>
                        <div className={`product-view-images-mode-tab ${listMode === 'images' ? 'selected' : ''} ${hideDetails ? 'hidden' : 'visible'}`} onClick={() => this.changeListMode('images')}>Imágenes</div>
                        <div className={`product-view-images-mode-tab ${listMode === 'similar' ? 'selected' : ''} ${hideDetails ? 'hidden' : 'visible'}`} onClick={() => this.changeListMode('similar')}>Similares</div>
                    </div>
                )}
                {listMode === 'images' && this.renderImagesList(showScrollbar, type)}
                {listMode === 'similar' && this.renderSimilarProducts(similar, showScrollbar, type)}
            </div>
        );
    };

    renderImagesList = (showScrollbar, type) => {
        const {product, selectedImageIndex, hideDetails} = this.state;
        return (
            <ImageList
                key={`product-image-list-${type}`}
                product={product}
                initialSelectedId={selectedImageIndex}
                has3dModel={!!this.state.product.viewItSceneUrl}
                showScrollbar={showScrollbar}
                visible={!hideDetails}
                disabled={hideDetails}
                onClick={this.changeImage}
            />
        );
    };

    renderSimilarProducts = (similar, showScrollbar, type) => {
        const {hideDetails} = this.state;
        const {style} = this.props;
        const discountBackgroundColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountBackgroundColor'] ? style['global']['prices']['discountBackgroundColor'] : undefined;
        const discountLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountLabelColor'] ? style['global']['prices']['discountLabelColor'] : undefined;
        const exclusivePriceLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceLabelColor'] ? style['global']['prices']['exclusivePriceLabelColor'] : undefined;
        const exclusivePriceIcon = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceIcon'] ? style['global']['prices']['exclusivePriceIcon'] : undefined;
        const offerBackgroundColor = style && style['global'] && style['global']['offers'] && style['global']['offers']['backgroundColor'] ? style['global']['offers']['backgroundColor'] : undefined;
        const offerLabelColor      = style && style['global'] && style['global']['offers'] && style['global']['offers']['labelColor'] ? style['global']['offers']['labelColor'] : undefined;
        return (
            <ProductBoxSlider
                key={`similar-products-list-${type}`}
                category={undefined}
                products={similar}
                showScrollbar={showScrollbar}
                visible={!hideDetails}
                disabled={hideDetails}
                discountBackgroundColor={discountBackgroundColor}
                discountLabelColor={discountLabelColor}
                exclusivePriceLabelColor={exclusivePriceLabelColor}
                exclusivePriceIcon={exclusivePriceIcon}
                offerBackgroundColor={offerBackgroundColor}
                offerLabelColor={offerLabelColor}
                onClick={this.toSimilarProduct}
            />
        );
    };

    renderIconButtonsGrid = () => {
        if (this.props.inSmallMode) {
            const {style} = this.props;
            const mobileBarBackground = style && style['product'] && style['product']['actionButtons'] && style['product']['actionButtons']['mobileBarBackground'] ? style['product']['actionButtons']['mobileBarBackground'] : undefined;
            return (
                <React.Fragment>
                    {this.props.inSmallMode && (
                        <div className='product-view-icon-buttons-grid-toggle' onClick={this.state.showActions ? () => this.closeActions('toggle-button') : this.openActions}>
                            <img className='product-view-icon-buttons-grid-toggle-icon' src={`${caretIcon}`} alt='toggle' draggable={false}/>
                        </div>
                    )}
                    {this.state.showActions && (
                        <Modal
                            mode='right'
                            smallMode={false}
                            backgroundColor={mobileBarBackground}
                            borderRadius={0}
                            padding='0'
                            width={130}
                            minWidth={130}
                            maxWidth={130}
                            height='100%'
                            minHeight='100%'
                            maxHeight='100%'
                            showDefaultCloseButton={true}
                            customDefaultCloseButtonPosition={{top: 10, right: 10}}
                            onClose={this.closeActions}
                        >
                            <div className='product-view-icon-buttons-grid mobile'>
                                <IconButtonsGrid
                                    id='product-view-icon-buttons-grid'
                                    mode='vertical'
                                    visible={true}
                                    disabled={false}
                                    buttons={this.getIconButtons()}
                                    defaultAction={() => this.closeActions('silent')}
                                />
                            </div>
                        </Modal>
                    )}
                </React.Fragment>
            );
        } else {
            return (
                <div className='product-view-icon-buttons-grid normal'>
                    <IconButtonsGrid
                        id='product-view-icon-buttons-grid'
                        mode='vertical'
                        visible={!this.state.hideDetails}
                        disabled={this.state.hideDetails}
                        buttons={this.getIconButtons()}
                        defaultAction={undefined}
                    />
                </div>
            );
        }
    };

    renderArModal = () => {
        const {style} = this.props;
        const qrScanImage = style && style['product'] && style['product']['arModalQrScanImage'] ? style['product']['arModalQrScanImage'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <ARModal
                product={this.state.product}
                arUrl={this.state.product.viewItArUrl}
                qrScanImage={qrScanImage}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onClose={this.closeArModal}
                metadata={this.getMetadata()}
            />
        );
    };

    renderProductDetailsSidebar = () => {
        const {product, showProductDetails} = this.state;
        const {style} = this.props;
        const tabHoverLabelColor = style && style['product'] && style['product']['productDetailsTabsHoverLabelColor'] ? style['product']['productDetailsTabsHoverLabelColor'] : undefined;
        const tabHoverBorderColor = style && style['product'] && style['product']['productDetailsTabsHoverBorderColor'] ? style['product']['productDetailsTabsHoverBorderColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <ProductDetailsSidebar
                isOpen={showProductDetails}
                product={product}
                tabHoverLabelColor={tabHoverLabelColor}
                tabHoverBorderColor={tabHoverBorderColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onClose={this.closeProductDetails}
                metadata={this.getMetadata()}
            />
        );
    };

    renderShareModal = () => {
        const {style} = this.props;
        const qrScanImage = style && style['product'] && style['product']['arModalQrScanImage'] ? style['product']['arModalQrScanImage'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <ShareModal
                product={this.state.product}
                shareUrl={this.state.product.viewItSceneUrl}
                qrScanImage={qrScanImage}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onClose={this.closeShareModal}
                metadata={this.getMetadata()}
            />
        );
    };

    render() {
        let {style} = this.props;
        const viewport = Viewport.dimensions;
        const showTopGallery = viewport.height >= 1400;
        const actionButtonBackgroundColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['backgroundColor'] ? style.global['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['borderColor'] ? style.global['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['labelColor'] ? style.global['actionButton']['labelColor'] : undefined;
        return (
            <div className='product-view'>
                {this.loadingData() && (
                    <div className='product-view-loader'>
                        <div className='product-view-loader-wrapper'>
                            <DataLoader message='Cargando información del producto...'/>
                        </div>
                    </div>
                )}
                {!this.loadingData() && !this.dataLoaded() && (
                    <React.Fragment>
                        {this.state.notFound ? (
                            <NotFound message={undefined} actionLabel={this.state.errorActionLabel} action={this.state.errorAction} style={this.props.style}/>
                        ) : (
                            <div className='product-view-error'>
                                <div className='product-view-error-wrapper'>
                                    <DataError
                                        message={this.state.errorMessage}
                                        actionLabel={this.state.errorActionLabel}
                                        action={this.state.errorAction}
                                        actionButtonLabelColor={actionButtonLabelColor}
                                        actionButtonBackgroundColor={actionButtonBackgroundColor}
                                        actionButtonBorderColor={actionButtonBorderColor}
                                    />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {!this.loadingData() && this.dataLoaded() && (
                    <React.Fragment>
                        <div className='product-view-content' style={this.contentStyle()}>
                            <div className='product-view-header-wrapper' style={this.listStyle()}>
                                {this.renderTitle()}
                                {showTopGallery && this.renderList(false, 'top')}
                            </div>
                            {!!this.state.selectedImageIndex && (
                                <div className='product-view-viewer'>
                                    {this.state.selectedImageIndex === '3D' && (
                                        <ModelViewer
                                            ref={this._iframeRef}
                                            url={this.buildModelViewerUrl()}
                                            errorActionButtonLabelColor={actionButtonLabelColor}
                                            errorActionButtonBackgroundColor={actionButtonBackgroundColor}
                                            errorActionButtonBorderColor={actionButtonBorderColor}
                                        />
                                    )}
                                    {this.state.selectedImageIndex !== '3D' && (
                                        <ImageViewer product={this.state.product} selectedImageIndex={this.state.selectedImageIndex}/>
                                    )}
                                </div>
                            )}
                            {!this.state.showProductDetails && this.renderIconButtonsGrid()}
                            {!showTopGallery && (
                                <div className='product-view-footer-wrapper' style={this.listStyle()}>
                                    {this.renderList(true, 'bottom')}
                                </div>
                            )}
                            {this.state.showArModal && !this.props.showInactivityModal && this.renderArModal()}
                            {this.state.showShareModal && !this.props.showInactivityModal && this.renderShareModal()}
                        </div>
                        {this.renderProductDetailsSidebar()}
                    </React.Fragment>
                )}
            </div>
        );
    };
}

export default withRouter(Product);

Product.propTypes = {
    params: PropTypes.object,
    client: PropTypes.string,
    instance: PropTypes.object,
    catalog: PropTypes.object,
    store: PropTypes.object,
    session: PropTypes.object,
    style: PropTypes.object,
    zoomLevel: PropTypes.number,
    inSmallMode: PropTypes.bool,
    openShoppingCart: PropTypes.func,
    addItemToCart: PropTypes.func,
    openActionBar: PropTypes.func,
    closeActionBar: PropTypes.func,
    openActionBarMobile: PropTypes.func,
    closeActionBarMobile: PropTypes.func,
    showInactivityModal: PropTypes.bool,
    algoliaInsights: PropTypes.object
};