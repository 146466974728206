import React from 'react';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import './PaymentModalOption.css';

function PaymentModalOption({type, icon, label1, label2, waitingMode, waitingModeIcon, waitingModeLabel, onClick}) {
    const handleClick = () => {
        if (!waitingMode && onClick) onClick(type);
    };

    return (
        <div className='payment-modal-option' onClick={handleClick}>
            <div className='payment-modal-option-icon'>
                <ImageWrapper image={icon} alt={type} display='block' width='100%' height='auto' loadDelay={0.2}/>
            </div>
            <p className='payment-modal-option-label'>
                {label1}
                <span className='payment-modal-option-label-bold-section'> {label2}</span>
            </p>
            {waitingMode && (
                <div className='payment-modal-option-timer'>
                    <img className='payment-modal-option-timer-icon' src={waitingModeIcon} alt='Waiting'/>
                    <div className='payment-modal-option-timer-label'>{waitingModeLabel}</div>
                </div>
            )}
        </div>
    );
}

export default PaymentModalOption;