import algoliaSearch from 'algoliasearch/lite';
import {ALGOLIA_APP_ID, ALGOLIA_API_KEY} from "./App";
import {Feature as _Feature, Measure as _Measure, Multimedia as _Multimedia, MultimediaItem as _MultimediaItem, Product as _Product} from "../models/Product";
import Debug from '../helpers/Debug';
import Environment from './Environment';

export const algoliaSearchClient = algoliaSearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

export const itemSearchSettings = (catalog, hitsPerPage) => {
    const categories = catalog ? catalog.categories : [];
    let categoryQuery = '';
    categories.forEach((category, index) => {
        categoryQuery += `categories:${category}`;
        if (index < categories.length - 1) categoryQuery += ' OR ';
    });
    const query = {
        filters: `(${categoryQuery}) AND maxPerItem > 0`,
        clickAnalytics: true
    };
    if (hitsPerPage) query.hitsPerPage = hitsPerPage;
    return query;
};

export const getRecommendedItems = (client, model, productId, threshold, maxRecommendations) => {
    const url = `https://${ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/*/recommendations`;
    const environment = Environment.current;
    const indexName = `${environment}_${client}_items`;
    const body = {
        requests: [{
            indexName: indexName,
            model: model,
            threshold: threshold,
            maxRecommendations: maxRecommendations,
            objectID: productId
        }]
    };
    return fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'X-Algolia-Application-Id': ALGOLIA_APP_ID,
            'X-Algolia-API-Key': ALGOLIA_API_KEY
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            if (!response.ok) throw new Error('Invalid response');
            return response.json();
        })
        .then((response) => {
            const hits = response['results'][0]['hits'];
            return hits.map((hit) => buildProductObject(hit));
        })
        .catch((error) => {
            Debug.printToLog('error', error);
            return undefined;
        });
}

export const buildProductObject = (hit) => {
    const prices = _Product.getSearchMainPriceGroup(hit['prices']);
    const deliveryOptions = hit['delivery'] && hit['delivery'].length > 0 ? hit['delivery'] : [];
    return new _Product({
        id: hit['objectID'],
        sku: hit['sku'],
        ean: undefined,
        name: hit['name'],
        brand: hit['brand'],
        description: hit['description'],
        longDescription: undefined,
        isFeatured: hit['isFeatured'],
        categories: hit['categories'],
        categoryPaths: undefined,
        multimedia: hit['image'] ? new _Multimedia({gallery: undefined, mainImage: new _MultimediaItem({m: hit['image']})}) : undefined,
        createdAt: undefined,
        updatedAt: undefined,
        originUpdatedAt: undefined,
        normalPrice: prices.normal ? prices.normal.value : undefined,
        offerPrice: prices.offer ? prices.offer.value : undefined,
        exclusivePrice: prices.exclusive ? prices.exclusive.value : undefined,
        maxPerItem: hit['maxPerItem'],
        has3dModel: hit['has3dModel'],
        delivery: deliveryOptions.includes('Shipping'),
        freeDelivery: deliveryOptions.includes('FreeShipping'),
        pickup: deliveryOptions.includes('Pickup'),
        features: hit['features'] && hit['features'].length > 0 ? hit['features'].map((feature) => new _Feature({name: feature[0], value: feature[1], order: feature[2], indexable: feature[3], visible: true})) : [],
        measures: hit['measures'] && hit['measures'].length > 0 ? hit['measures'].map((measure) => new _Measure({name: measure[0], value: measure[1], unit: measure[2]})) : [],
        variants: [],
        score: hit['_score']
    });
};